import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Container,
  Divider,
  Grid,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography,
  Zoom,
} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useNavigate } from 'react-router-dom';
import aseguradoraService from 'services/aseguradoraService/aseguradoraService';
import { setItem } from 'services/sessionStorageService';

import informesService from 'services/informesService/informesService';
import { LoginFormValues } from './types';
import messages from './messages';
import validate from './validator';
import styles from './LoginForm.module.scss';
import authService from '../../services/authService';

const initialValues: LoginFormValues = {
  email: '',
  password: '',
};

const NODE_ENV_PRODUCCTION = 'production';
const KEY_ASEGURADORA_LOGGED =
  process.env.REACT_APP_KEY_ASEGURADORA_LOGGED ?? '';
const BASE_URL = process.env.REACT_APP_BASE_URL ?? '';

const LoginForm: React.FC = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const [showLoginProgress, setShowLoginProgress] = useState(false);
  const [errorLogin, setErrorLogin] = useState('');
  const [patente, setPatente] = useState<string>('');
  const [errorSearchPatente, setErrorSearchPatente] = useState<string>('');
  const [showSearchProgress, setShowSearchProgress] = useState(false);
  const [enabledSubmitPatente, setEnabledSubmitPatente] =
    useState<boolean>(false);
  const [expanded, setExpanded] = useState(false);

  const submitHandler = async (values: LoginFormValues): Promise<void> => {
    try {
      setShowLoginProgress(true);
      const response = await authService.login({
        email: values.email,
        password: values.password,
      });

      // eslint-disable-next-line no-console
      console.log('response', response);

      const aseguradora = await aseguradoraService.getAseguradora();

      if (KEY_ASEGURADORA_LOGGED && aseguradora?.razon_social !== '') {
        setItem(KEY_ASEGURADORA_LOGGED, aseguradora.razon_social);
      }

      navigate('/informes');
    } catch (error) {
      setErrorLogin(formatMessage(messages.textErrorLogin));
    } finally {
      setShowLoginProgress(false);
    }
  };

  const { values, errors, touched, handleChange, handleSubmit } = useFormik({
    initialValues,
    validate,
    onSubmit: submitHandler,
  });

  const handleChangePatente = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPatente = event.target.value;
    setPatente(event.target.value);
    if (newPatente.length > 5) {
      setEnabledSubmitPatente(true);
    } else {
      setEnabledSubmitPatente(false);
    }
  };

  const handleSubmitPatente = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault();
    setShowSearchProgress(true);
    setErrorSearchPatente('');
    try {
      const response = await informesService.trackInforme(patente);
      if ('url' in response) {
        window.open(response.url, '_blank');
      } else if ('error' in response) {
        const errorMessage = response.error[0];
        setErrorSearchPatente(errorMessage);
      }
    } catch (error) {
      if (error instanceof Error) {
        setErrorSearchPatente(error.message);
      } else {
        setErrorSearchPatente(formatMessage(messages.textErrorSearch));
      }
    } finally {
      setShowSearchProgress(false);
    }
  };

  const handleToggleExpand = () => {
    setExpanded(prevExpanded => !prevExpanded);
  };

  return (
    <Box minHeight="100vh" className={styles.background_appsolut}>
      <Zoom in style={{ transitionDelay: '500ms' }}>
        <Box
          flexGrow={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Card className={styles['card-form-patente']}>
            <Box py={2}>
              <CardMedia
                component="img"
                height="120"
                image="./appsolut_logo_chico.jpg"
                alt={formatMessage(messages.imgLogoTitle)}
                title={formatMessage(messages.imgLogoTitle)}
              />
            </Box>
            <Divider className={styles.divider} />
            <Typography
              variant="h5"
              component="h1"
              color="info.dark"
              align="center"
            >
              {formatMessage(messages.formPatenteTitulo)}{' '}
              {process.env.NODE_ENV !== NODE_ENV_PRODUCCTION &&
                `(${process.env.NODE_ENV})`}
            </Typography>
            <Typography variant="subtitle1" mt={2} align="center">
              {formatMessage(messages.formPatenteSubtitulo)}
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                // eslint-disable-next-line @typescript-eslint/no-floating-promises
                handleSubmitPatente(e);
              }}
              px={2}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="patente"
                label={formatMessage(messages.inputPatente)}
                name="patente"
                value={patente}
                autoComplete="patente"
                autoFocus
                onChange={handleChangePatente}
                placeholder="Ingrese Patente"
                helperText={formatMessage(messages.inputPatenteHelperText)}
              />
              {errorSearchPatente.trim() !== '' && (
                <Typography color="error">{errorSearchPatente}</Typography>
              )}
              {showSearchProgress && (
                <Stack alignItems="center">
                  <CircularProgress />
                </Stack>
              )}
              <Grid container alignItems="center" my={2}>
                <Grid item xs={6} />
                <Grid item xs={6}>
                  <Button
                    type="submit"
                    name="btnPatente"
                    fullWidth
                    variant="contained"
                    disabled={!enabledSubmitPatente}
                  >
                    Buscar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Card>

          <Card className={styles['card-form-login']}>
            <CardHeader
              title={formatMessage(messages.formLoginHeader)}
              action={
                <IconButton
                  aria-expanded={expanded}
                  aria-label="show more"
                  onClick={handleToggleExpand}
                >
                  <ExpandMoreIcon />
                </IconButton>
              }
              sx={{ textAlign: 'center' }}
            />
            {expanded && (
              <CardContent>
                <Typography variant="subtitle2" mt={2} align="center">
                  {formatMessage(messages.subtitulo)}
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} px={2}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label={formatMessage(messages.inputEmail)}
                    name="email"
                    value={values.email}
                    autoComplete="email"
                    autoFocus
                    onChange={handleChange}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    label={formatMessage(messages.inputPassword)}
                    name="password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={values.password}
                    onChange={handleChange}
                    error={touched.password && Boolean(errors.password)}
                    helperText={touched.password && errors.password}
                  />
                  {errorLogin.trim() !== '' && (
                    <Typography color="error">{errorLogin}</Typography>
                  )}
                  {showLoginProgress && (
                    <Stack alignItems="center">
                      <CircularProgress />
                    </Stack>
                  )}
                  <Grid container alignItems="center" my={2}>
                    <Grid item xs={6}>
                      <Link href={`${BASE_URL}/password/reset`} variant="body2">
                        {formatMessage(messages.linkRecuperarClave)}
                      </Link>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        type="submit"
                        name="login"
                        fullWidth
                        variant="contained"
                      >
                        {formatMessage(messages.buttonLogin)}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            )}
          </Card>
        </Box>
      </Zoom>
      <footer className={styles['footer-login']}>
        <Container maxWidth="sm">
          <Grid container justifyContent="center" columnSpacing={1}>
            <Grid item xs={6}>
              <Typography align="center">
                <PhoneIcon />
              </Typography>
              <Typography variant="subtitle1" align="center">
                {formatMessage(messages.telefono1)}
              </Typography>
              <Typography variant="subtitle1" align="center">
                {formatMessage(messages.telefono2)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography align="center">
                <MailOutlineIcon />
              </Typography>
              <Typography variant="subtitle1" align="center">
                {formatMessage(messages.mail)}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <div className={styles['footer-login-copyright']}>
          <Typography variant="subtitle2" align="center">
            {'Copyright © '} {formatMessage(messages.nombreEmpresa)}{' '}
            {new Date().getFullYear()}.
          </Typography>
        </div>
      </footer>
    </Box>
  );
};

export default LoginForm;
