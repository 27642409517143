// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PrestadoresHabilitadosListWrapper_divider-title__jB4wj{background:#42a5f5;height:.25rem}.PrestadoresHabilitadosListWrapper_divider-row__Wo-TE{background:#bdbdbd}.PrestadoresHabilitadosListWrapper_paper-search__pl-VI{display:flex;align-items:center;padding:.3rem .5rem;margin:.5rem 0}.PrestadoresHabilitadosListWrapper_input-base-search__qJuD2{flex:1;margin-left:.25rem}.PrestadoresHabilitadosListWrapper_input-divider-search__kRlzO{height:2rem;margin:0 .25rem}", "",{"version":3,"sources":["webpack://./src/components/PrestadoresHabilitadosListWrapper/PrestadoresHabilitadosListWrapper.module.scss"],"names":[],"mappings":"AAAA,wDACI,kBAAA,CACA,aAAA,CAGJ,sDACI,kBAAA,CAGJ,uDACI,YAAA,CACA,kBAAA,CACA,mBAAA,CACA,cAAA,CAGJ,4DACI,MAAA,CACA,kBAAA,CAGJ,+DACI,WAAA,CACA,eAAA","sourcesContent":[".divider-title {\n    background: #42a5f5;\n    height: 0.25rem;\n}\n\n.divider-row {\n    background: #bdbdbd;\n}\n\n.paper-search {\n    display: flex;\n    align-items: center;\n    padding: 0.3rem .5rem;\n    margin: 0.5rem 0;\n}\n\n.input-base-search {\n    flex: 1;\n    margin-left: .25rem;\n}\n\n.input-divider-search {\n    height: 2rem;\n    margin: 0 .25rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"divider-title": "PrestadoresHabilitadosListWrapper_divider-title__jB4wj",
	"divider-row": "PrestadoresHabilitadosListWrapper_divider-row__Wo-TE",
	"paper-search": "PrestadoresHabilitadosListWrapper_paper-search__pl-VI",
	"input-base-search": "PrestadoresHabilitadosListWrapper_input-base-search__qJuD2",
	"input-divider-search": "PrestadoresHabilitadosListWrapper_input-divider-search__kRlzO"
};
export default ___CSS_LOADER_EXPORT___;
