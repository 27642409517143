const calcularPorcentaje = ({
  parcial,
  total,
}: {
  parcial: number;
  total: number;
}): number | undefined => {
  if (total === 0) {
    return undefined;
  }

  return Math.round((parcial / total) * 100);
};

const convertirAHorasYMinutos = ({
  totalMinutos,
}: {
  totalMinutos: number;
}): string => {
  const minutosTranscurridos = Math.trunc(totalMinutos);
  if (minutosTranscurridos >= 60) {
    const horas = Math.floor(minutosTranscurridos / 60);
    const minutos = minutosTranscurridos % 60;
    return `${horas}h ${minutos}m`;
  }
  return `${minutosTranscurridos} minutos`;
};

const convertirformatoMoneda = ({ value }: { value: number }): string => {
  const result = value.toLocaleString('es-ar', {
    style: 'currency',
    currency: 'ARS',
    minimumFractionDigits: 0,
  });
  return result;
};

export { calcularPorcentaje, convertirAHorasYMinutos, convertirformatoMoneda };
