// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PresupuestosEstadisticas_th-bgcolor-green__86WbJ{background-color:#a5d6a7}.PresupuestosEstadisticas_tr-bgcolor__o69Q7:nth-child(odd){background-color:#e8f5e9}", "",{"version":3,"sources":["webpack://./src/components/Dashboard/PresupuestosEstadisticas/PresupuestosEstadisticas.module.scss"],"names":[],"mappings":"AAAA,kDACC,wBAAA,CAGD,2DACC,wBAAA","sourcesContent":[".th-bgcolor-green {\n\tbackground-color: #a5d6a7;\n}\n\n.tr-bgcolor:nth-child(odd) {\t\n\tbackground-color: #e8f5e9;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"th-bgcolor-green": "PresupuestosEstadisticas_th-bgcolor-green__86WbJ",
	"tr-bgcolor": "PresupuestosEstadisticas_tr-bgcolor__o69Q7"
};
export default ___CSS_LOADER_EXPORT___;
