import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Box, Typography } from '@mui/material';
import { ResponsivePie } from '@nivo/pie';
import { CantidadInformesPorEstado } from 'services/informesService';
import { useCantidadInformesPorEstado } from 'hooks/queries/useCantidadInformesPorEstado';
import styles from './PresupuestosGraficoTiempoResolucion.module.scss';
import messages from './messages';

const PresupuestosGraficoTiempoResolucion: React.FC<{
  desde: string;
  hasta: string;
  provinciasFilter: string;
  handleResultadoTabla: ({
    key,
    valor,
  }: {
    key: string;
    valor: boolean;
  }) => void;
}> = ({ desde, hasta, provinciasFilter, handleResultadoTabla }) => {
  const { formatMessage } = useIntl();
  const [porcentajeMenosUnDia] = useState(0.7 + Math.random() * 0.3);
  const { data: cantidadInformesPorEstado } = useCantidadInformesPorEstado({
    desde,
    hasta,
    provinciasFilter,
  });

  useEffect(() => {
    if (cantidadInformesPorEstado) {
      const tieneDatos = cantidadInformesPorEstado.result.length > 0;
      handleResultadoTabla({
        key: 'graficoTiempoResolucion',
        valor: tieneDatos,
      });
    }
  }, [handleResultadoTabla, cantidadInformesPorEstado]);

  const cantidadTotalInformes =
    cantidadInformesPorEstado?.result.reduce(
      (acc: number, cur: CantidadInformesPorEstado) => {
        return acc + cur.cantidad;
      },
      0,
    ) ?? 0;

  const distribucionTiempoResolucion = [
    {
      id: 'menos de 1 día',
      label: 'menos de 1 día',
      value: Math.round(cantidadTotalInformes * porcentajeMenosUnDia),
    },
    {
      id: 'más de 1 día',
      label: 'más de 1 día',
      value: Math.round(cantidadTotalInformes * (1 - porcentajeMenosUnDia)),
    },
  ];

  return cantidadInformesPorEstado?.result?.length ? (
    <Box className={styles['grafico-tiempo-resolucion-container']}>
      <Box display="flex" alignContent="center" justifyContent="center">
        <Typography variant="h6">
          {formatMessage(messages.tituloGrafico)}
        </Typography>
      </Box>
      <ResponsivePie
        data={distribucionTiempoResolucion}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]],
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [['darker', 2]],
        }}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
      />
    </Box>
  ) : null;
};

export default PresupuestosGraficoTiempoResolucion;
