import React, { useEffect } from 'react';
import { useEstadisticaComprasPorAdjudicacion } from 'hooks/queries/useEstadisticaComprasPorAdjudicacion/useEstadisticaComprasPorAdjudicacion';
import ComprasPorMotivoAdjudicacion from '../ComprasPorMotivoAdjudicacion';

const ComprasPorMotivoAdjudicacionContainer: React.FC<{
  desde: string;
  hasta: string;
  provinciasFilter: string;
  handleResultadoTabla: ({
    key,
    valor,
  }: {
    key: string;
    valor: boolean;
  }) => void;
}> = ({ desde, hasta, provinciasFilter, handleResultadoTabla }) => {
  const { data: comprasPorAdjudicacion } = useEstadisticaComprasPorAdjudicacion(
    { desde, hasta, provinciasFilter },
  );

  useEffect(() => {
    if (comprasPorAdjudicacion) {
      const tieneDatos = comprasPorAdjudicacion?.length > 0;
      handleResultadoTabla({
        key: 'comprasPorMotivoAdjudicacion',
        valor: tieneDatos,
      });
    }
  }, [handleResultadoTabla, comprasPorAdjudicacion]);

  if (!comprasPorAdjudicacion) {
    return null;
  }

  return comprasPorAdjudicacion?.length > 0 ? (
    <ComprasPorMotivoAdjudicacion
      rankingPorAdjudicacion={comprasPorAdjudicacion}
    />
  ) : null;
};

export default ComprasPorMotivoAdjudicacionContainer;
