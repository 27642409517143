import { useEstadisticaRankingPrestadores } from 'hooks/queries/useEstadisticaRankingPrestadores';
import React, { useEffect, useState } from 'react';
import { EstadisticaRankingPrestadores } from 'services/presupuestosService';
import PresupuestosRankingPrestadores from '../PresupuestosRankingPrestadores';
import { TableRanking } from '../PresupuestosRankingPrestadores/types';

const PresupuestosRankingPrestadoresContainer: React.FC<{
  desde: string;
  hasta: string;
  provinciasFilter: string;
  handleResultadoTabla: ({
    key,
    valor,
  }: {
    key: string;
    valor: boolean;
  }) => void;
}> = ({ desde, hasta, provinciasFilter, handleResultadoTabla }) => {
  const [rankingOrdenado, setRankingOrdenado] = useState<TableRanking[]>([]);
  const { data: rankingPrestadores } = useEstadisticaRankingPrestadores({
    desde,
    hasta,
    provinciasFilter,
  });
  const [sortField, setSortField] = useState('Total');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');

  useEffect(() => {
    if (rankingPrestadores) {
      const groupedByPrestador = rankingPrestadores.reduce(
        (result: TableRanking[], item: EstadisticaRankingPrestadores) => {
          const {
            prestador,
            razon,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            cantidad_adjudicados,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            subtotal_adjudicados,
          } = item;
          const existingPrestador = result.find(
            entry => entry.prestador === prestador,
          );
          if (existingPrestador) {
            existingPrestador.total += subtotal_adjudicados;
            existingPrestador.cantidad += cantidad_adjudicados;
            const existingRazon = existingPrestador.adjudicados.find(
              p => p.razon === razon,
            );
            if (existingRazon) {
              existingRazon.cantidad_adjudicados += cantidad_adjudicados;
              existingRazon.subtotal_adjudicados += subtotal_adjudicados;
            } else {
              existingPrestador.adjudicados = [
                ...existingPrestador.adjudicados,
                {
                  razon,
                  cantidad_adjudicados,
                  subtotal_adjudicados,
                },
              ];
            }
          } else {
            return [
              ...result,
              {
                prestador,
                total: subtotal_adjudicados,
                cantidad: cantidad_adjudicados,
                adjudicados: [
                  {
                    razon,
                    cantidad_adjudicados,
                    subtotal_adjudicados,
                  },
                ],
              },
            ];
          }
          return result;
        },
        [] as TableRanking[],
      );
      const tableRankingOrdenado = groupedByPrestador
        .slice()
        .sort((a: TableRanking, b: TableRanking) => b.total - a.total);
      setRankingOrdenado(tableRankingOrdenado);
    }
  }, [rankingPrestadores]);

  useEffect(() => {
    const tieneDatos = rankingOrdenado?.length > 0;
    handleResultadoTabla({
      key: 'presupuestosRankingPrestadores',
      valor: tieneDatos,
    });
  }, [handleResultadoTabla, rankingOrdenado?.length]);

  const handleSortingChange = ({ column }: { column: string }) => {
    const sortOrder = column === sortField && order === 'desc' ? 'asc' : 'desc';
    setSortField(column);
    setOrder(sortOrder);
    if (column === 'Prestador') {
      const tableRankingOrdenado = rankingOrdenado
        .slice()
        .sort((a: TableRanking, b: TableRanking) =>
          order === 'desc'
            ? b.prestador
                .toLocaleLowerCase()
                .localeCompare(a.prestador.toLocaleLowerCase())
            : a.prestador
                .toLocaleLowerCase()
                .localeCompare(b.prestador.toLocaleLowerCase()),
        );
      setRankingOrdenado(tableRankingOrdenado);
    }
    if (column === 'Cantidad') {
      const tableRankingOrdenado = rankingOrdenado
        .slice()
        .sort((a: TableRanking, b: TableRanking) =>
          order === 'desc' ? b.cantidad - a.cantidad : a.cantidad - b.cantidad,
        );
      setRankingOrdenado(tableRankingOrdenado);
    }
    if (column === 'Total ($)') {
      const tableRankingOrdenado = rankingOrdenado
        .slice()
        .sort((a: TableRanking, b: TableRanking) =>
          order === 'desc' ? b.total - a.total : a.total - b.total,
        );
      setRankingOrdenado(tableRankingOrdenado);
    }
  };

  return rankingOrdenado?.length > 0 ? (
    <PresupuestosRankingPrestadores
      ranking={rankingOrdenado}
      sortColumn={sortField}
      sortDirection={order}
      handleSortingChange={handleSortingChange}
    />
  ) : null;
};

export default PresupuestosRankingPrestadoresContainer;
