import { useQuery } from '@tanstack/react-query';
import {
  presupuestosService,
  PresupuestoEstadistica,
} from 'services/presupuestosService';

const ESTADISTICA_PRESUPUESTO_QUERY_KEY = 'estadisticaPresupuesto';

const useGetEstadisticaPresupuesto = ({
  desde,
  hasta,
  provinciasFilter,
}: {
  desde: string;
  hasta: string;
  provinciasFilter: string;
}) => {
  return useQuery<PresupuestoEstadistica[], Error>(
    [ESTADISTICA_PRESUPUESTO_QUERY_KEY, { desde, hasta, provinciasFilter }],
    () =>
      presupuestosService.getEstadistica({ desde, hasta, provinciasFilter }),
    {
      staleTime: 60 * 60 * 1000,
    },
  );
};

export { useGetEstadisticaPresupuesto, ESTADISTICA_PRESUPUESTO_QUERY_KEY };
