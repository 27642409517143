import { request, RequestMethods } from 'clients/innboxes1';
import { EstadisticaComprasPorMotivoAdjudicacion } from './types';

const URL_API_COMPRAS = process.env.REACT_APP_API_URL_COMPRAS || '';

const getComprasPorMotivoAdjudicacion = async ({
  desde,
  hasta,
  provinciasFilter,
}: {
  desde: string;
  hasta: string;
  provinciasFilter: string;
}): Promise<EstadisticaComprasPorMotivoAdjudicacion[]> => {
  const paramsDesdeHasta = `desde=${desde}&hasta=${hasta}`;
  const queryParams =
    provinciasFilter !== ''
      ? `${paramsDesdeHasta}&${provinciasFilter}`
      : paramsDesdeHasta;

  const data = <EstadisticaComprasPorMotivoAdjudicacion[]>await request({
    method: RequestMethods.get,
    baseUrl: URL_API_COMPRAS,
    endpoint: `comprasPorMotivoAdjudicacion?${queryParams}`,
  });

  return data;
};

export default { getComprasPorMotivoAdjudicacion };
