import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'FallbackError.title',
    description: 'Titulo para el error',
    defaultMessage: 'Ha ocurrido un error inesperado',
  },
  buttonText: {
    id: 'FallbackError.buttonText',
    description: 'Texto para el botón',
    defaultMessage: 'Reintentar',
  },
});
