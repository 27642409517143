import { request, RequestMethods } from 'clients/innboxes1';
import {
  PresupuestoEstadistica,
  EstadisticaPromedioPrecios,
  EstadisticaRankingPrestadores,
} from './types';

const API_URL_PRESUPUESTOS = process.env.REACT_APP_API_URL_PRESUPUESTOS ?? '';

const getEstadistica = async ({
  desde,
  hasta,
  provinciasFilter,
}: {
  desde: string;
  hasta: string;
  provinciasFilter: string;
}): Promise<PresupuestoEstadistica[]> => {
  const paramsDesdeHasta = `desde=${desde}&hasta=${hasta}`;
  const queryParams =
    provinciasFilter !== ''
      ? `${paramsDesdeHasta}&${provinciasFilter}`
      : paramsDesdeHasta;

  const data = <PresupuestoEstadistica[]>await request({
    method: RequestMethods.get,
    baseUrl: API_URL_PRESUPUESTOS,
    endpoint: `estadistica?${queryParams}`,
  });

  return data;
};

const getPromedioPrecios = async ({
  desde,
  hasta,
  provinciasFilter,
}: {
  desde: string;
  hasta: string;
  provinciasFilter: string;
}): Promise<EstadisticaPromedioPrecios[]> => {
  const paramsDesdeHasta = `desde=${desde}&hasta=${hasta}`;
  const queryParams =
    provinciasFilter !== ''
      ? `${paramsDesdeHasta}&${provinciasFilter}`
      : paramsDesdeHasta;
  const data = <EstadisticaPromedioPrecios[]>await request({
    method: RequestMethods.get,
    baseUrl: API_URL_PRESUPUESTOS,
    endpoint: `promedioPrecios?${queryParams}`,
  });

  return data;
};

const getRankingPrestadores = async ({
  desde,
  hasta,
  provinciasFilter,
}: {
  desde: string;
  hasta: string;
  provinciasFilter: string;
}): Promise<EstadisticaRankingPrestadores[]> => {
  const paramsDesdeHasta = `desde=${desde}&hasta=${hasta}`;
  const queryParams =
    provinciasFilter !== ''
      ? `${paramsDesdeHasta}&${provinciasFilter}`
      : paramsDesdeHasta;
  const data = <EstadisticaRankingPrestadores[]>await request({
    method: RequestMethods.get,
    baseUrl: API_URL_PRESUPUESTOS,
    endpoint: `rankingPrestadores?${queryParams}`,
  });

  return data;
};

export default { getEstadistica, getPromedioPrecios, getRankingPrestadores };
