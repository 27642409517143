import { useCallback, useEffect, useRef, useState } from 'react';

const useIntersectionObserver = (): [
  React.RefObject<HTMLInputElement>,
  boolean,
] => {
  const ref = useRef<HTMLInputElement>(null);
  const [inView, setInView] = useState<boolean>(false);

  const callback = useCallback<IntersectionObserverCallback>(entries => {
    if (
      entries.find(
        item => item.isIntersecting && Math.floor(item.intersectionRatio) === 1,
      )
    ) {
      setInView(true);
    } else {
      setInView(false);
    }
  }, []);

  useEffect(() => {
    if (ref.current !== null) {
      const options = {
        rootMargin: '0px',
        threshold: 1.0,
      };

      const observer = new IntersectionObserver(callback, options);
      observer.observe(ref.current);
    }
  }, [ref, callback, inView]);

  return [ref, inView];
};

export default useIntersectionObserver;
