import React from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { EstadisticaPromedioPrecios } from 'services/presupuestosService';
import { useIntl } from 'react-intl';
import utils from 'utils';
import messages from './messages';
import styles from './PresupuestosPromedioPrecios.module.scss';

const PresupuestosPromedioPrecios: React.FC<{
  promedioPrecios: EstadisticaPromedioPrecios;
}> = ({ promedioPrecios }) => {
  const { formatMessage } = useIntl();
  return (
    <Box mt={2}>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow className={styles['th-bgcolor-purple']}>
              <TableCell>
                <strong>{formatMessage(messages.labelAhorro)}</strong>
              </TableCell>
              <TableCell align="center" colSpan={2}>
                <Typography variant="h6">
                  {Math.round(
                    (1 -
                      promedioPrecios.promedio_contratados /
                        promedioPrecios.promedio_presupuestados) *
                      100,
                  )}
                  %
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow className={styles['tr-bgcolor']}>
              <TableCell />
              <TableCell align="right">
                {formatMessage(messages.headerColumnPromedio)}
              </TableCell>
              <TableCell align="right">
                {formatMessage(messages.headerColumnSuma)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={styles['tr-bgcolor']}>
              <TableCell>{formatMessage(messages.preciosLicitados)}</TableCell>
              <TableCell align="right">
                {utils.convertirformatoMoneda({
                  value: Math.round(promedioPrecios.promedio_presupuestados),
                })}
              </TableCell>
              <TableCell align="right">
                {utils.convertirformatoMoneda({
                  value: promedioPrecios.total_presupuestados,
                })}
              </TableCell>
            </TableRow>
            <TableRow className={styles['tr-bgcolor']}>
              <TableCell>
                {formatMessage(messages.preciosContratados)}
              </TableCell>
              <TableCell align="right">
                {utils.convertirformatoMoneda({
                  value: Math.round(promedioPrecios.promedio_contratados),
                })}
              </TableCell>
              <TableCell align="right">
                {utils.convertirformatoMoneda({
                  value: promedioPrecios.total_contratados,
                })}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PresupuestosPromedioPrecios;
