import { StyledEngineProvider } from '@mui/material/styles';
import {
  Autocomplete,
  Button,
  Chip,
  Divider,
  Grid,
  InputBase,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import PrestadoresHabilitadosListView from 'components/PrestadoresHabilitadosListView';
import React, { Suspense, useState } from 'react';
import { useIntl } from 'react-intl';
import NoResults from 'components/NoResults';
import Loading from 'components/Loading';
import { Prestador } from 'services/prestadoresHabilitadosService';
import ProvinciasFiltereMultiselect, {
  Provincia,
} from 'components/ProvinciasFilterMultiselect';
import { PrestadorRubro } from 'pages/PrestadoresHabilitadosListContainer/types';
import rubros from 'pages/PrestadoresHabilitadosListContainer/prestadoresRubros.json';
import provincias from 'components/ProvinciasFilterMultiselect/provincias.json';
import messages from './messages';
import styles from './PrestadoresHabilitadosListWrapper.module.scss';

const PrestadoresHabilitadosListWrapper: React.FC<{
  prestadoresHabilitados: Prestador[] | undefined;
  lastElement: React.RefObject<HTMLInputElement>;
  isFetchingNextPage: boolean;
  setQueryText: React.Dispatch<React.SetStateAction<string>>;
  provinciasSelected: Provincia[];
  setProvinciasSelected: React.Dispatch<React.SetStateAction<Provincia[]>>;
  rubrosSelected: PrestadorRubro[];
  setRubrosSelected: React.Dispatch<React.SetStateAction<PrestadorRubro[]>>;
}> = ({
  prestadoresHabilitados,
  lastElement,
  isFetchingNextPage,
  setQueryText,
  provinciasSelected,
  setProvinciasSelected,
  rubrosSelected,
  setRubrosSelected,
}) => {
  const { formatMessage } = useIntl();
  // const rubros = _rubrosPrestador as PrestadorRubro[];

  const [searchText, setSearchText] = useState<string>('');
  const handleClickSearch = () => {
    setQueryText(searchText);
  };

  return (
    <>
      <StyledEngineProvider injectFirst>
        <Grid
          container
          justifyContent="space-around"
          alignItems="center"
          rowSpacing={1}
          columnSpacing={1}
          mt={2}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h6" align="left">
              <strong>{formatMessage(messages.titleList)}</strong>
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <ProvinciasFiltereMultiselect
              provincias={provincias}
              provinciasSelected={provinciasSelected}
              setProvinciasSelected={setProvinciasSelected}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Autocomplete
              multiple
              id="rubros-autocomplete"
              limitTags={1}
              value={rubrosSelected}
              onChange={(_event, newValue) => {
                setRubrosSelected([...newValue]);
              }}
              options={rubros}
              getOptionLabel={option => option.nombre}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    label={option.nombre}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...getTagProps({ index })}
                  />
                ))
              }
              className={styles['autocomplete-style']}
              renderInput={params => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label={formatMessage(messages.labelRubroTexto)}
                  placeholder={formatMessage(messages.inputRubroPlaceholder)}
                />
              )}
              renderOption={(props, option, { selected }) => (
                <MenuItem
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                  key={option.nombre}
                  value={option.nombre}
                  sx={{ justifyContent: 'space-between' }}
                >
                  {option.nombre}
                  {selected ? <CheckIcon color="info" /> : null}
                </MenuItem>
              )}
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <Paper
              component="form"
              elevation={3}
              className={styles['paper-search']}
            >
              <InputBase
                placeholder={formatMessage(messages.inputBasePlaceholder)}
                inputProps={{
                  'aria-label': formatMessage(messages.inputBaseLabel),
                }}
                value={searchText}
                onInput={e => {
                  const inputValue = (e.target as HTMLInputElement).value;
                  setSearchText(inputValue);
                }}
                className={styles['input-base-search']}
              />
              <Divider
                orientation="vertical"
                className={styles['input-divider-search']}
              />
              <Button variant="contained" onClick={handleClickSearch}>
                {formatMessage(messages.buttonBuscarTexto)}
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </StyledEngineProvider>
      <Divider className={styles['divider-title']} />
      {prestadoresHabilitados && prestadoresHabilitados.length > 0 ? (
        <Suspense fallback={<Loading />}>
          <PrestadoresHabilitadosListView
            prestadoresHabilitados={prestadoresHabilitados}
            lastElement={lastElement}
            isFetchingNextPage={isFetchingNextPage}
          />
        </Suspense>
      ) : (
        <NoResults
          title={formatMessage(messages.titleList)}
          message={formatMessage(messages.labelNoResults)}
        />
      )}
    </>
  );
};

export default PrestadoresHabilitadosListWrapper;
