import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import utils from 'utils';
import {
  TableComprasPorMotivoAdjudicacion,
  TotalesComprasPorMotivoAdjudicacion,
} from './types';
import messages from './messages';
import styles from './ComprasPorMotivoAdjudicacion.module.scss';

const ComprasPorMotivoAdjudicacion: React.FC<{
  rankingPorAdjudicacion: TableComprasPorMotivoAdjudicacion[];
}> = ({ rankingPorAdjudicacion }) => {
  const { formatMessage } = useIntl();
  const [totalesComprasPorMotivos, setTotalesComprasPorMotivos] =
    useState<TotalesComprasPorMotivoAdjudicacion>({
      total_enviadas: 0,
      total_documentacion_cargada: 0,
    });

  useEffect(() => {
    const totales = rankingPorAdjudicacion.reduce(
      (result: TotalesComprasPorMotivoAdjudicacion, item) => {
        return {
          total_enviadas: result.total_enviadas + item.cantidad,
          total_documentacion_cargada:
            result.total_documentacion_cargada + item.documentacion_cargada,
        };
      },
      { total_enviadas: 0, total_documentacion_cargada: 0 },
    );
    setTotalesComprasPorMotivos(totales);
  }, [rankingPorAdjudicacion]);

  return (
    <Box mt={2}>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead className={styles['th-color']}>
            <TableRow>
              <TableCell>
                <strong>{formatMessage(messages.title)}</strong>
              </TableCell>
              <TableCell align="right">
                {formatMessage(messages.headCantidad)}
              </TableCell>
              <TableCell align="right">
                {formatMessage(messages.headPorcentaje)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={styles['tr-total']} key="TotalEnvidas">
              <TableCell>{formatMessage(messages.colEnviadas)}</TableCell>
              <TableCell align="right">
                {totalesComprasPorMotivos.total_enviadas}
              </TableCell>
              <TableCell align="right">
                {utils.calcularPorcentaje({
                  parcial: totalesComprasPorMotivos.total_enviadas,
                  total: totalesComprasPorMotivos.total_enviadas,
                })}
                %
              </TableCell>
            </TableRow>
            {rankingPorAdjudicacion?.length > 0 &&
              rankingPorAdjudicacion.map(motivo => (
                <TableRow key={motivo.razon}>
                  <TableCell className={styles['tc-first-col']}>
                    {motivo.razon}
                  </TableCell>
                  <TableCell align="right">{motivo.cantidad}</TableCell>
                  <TableCell align="right">
                    {utils.calcularPorcentaje({
                      parcial: motivo.cantidad,
                      total: totalesComprasPorMotivos.total_enviadas,
                    })}
                    %
                  </TableCell>
                </TableRow>
              ))}
            <TableRow
              className={styles['tr-total']}
              key="TotalDocumentacionCargada"
            >
              <TableCell>{formatMessage(messages.colDocumentacion)}</TableCell>
              <TableCell align="right">
                {totalesComprasPorMotivos.total_documentacion_cargada}
              </TableCell>
              <TableCell align="right">
                {utils.calcularPorcentaje({
                  parcial: totalesComprasPorMotivos.total_documentacion_cargada,
                  total: totalesComprasPorMotivos.total_enviadas,
                })}
                %
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ComprasPorMotivoAdjudicacion;
