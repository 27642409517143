// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PresupuestosGraficoTiempoResolucion_grafico-tiempo-resolucion-container__1z8wt{height:25rem;width:25rem}", "",{"version":3,"sources":["webpack://./src/components/Dashboard/PresupuestosGraficoTiempoResolucion/PresupuestosGraficoTiempoResolucion.module.scss"],"names":[],"mappings":"AAAA,gFACE,YAAA,CACA,WAAA","sourcesContent":[".grafico-tiempo-resolucion-container {\n  height: 25rem;\n  width: 25rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"grafico-tiempo-resolucion-container": "PresupuestosGraficoTiempoResolucion_grafico-tiempo-resolucion-container__1z8wt"
};
export default ___CSS_LOADER_EXPORT___;
