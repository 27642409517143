import PrestadoresHabilitadosListWrapper from 'components/PrestadoresHabilitadosListWrapper';
import { Provincia } from 'components/ProvinciasFilterMultiselect';
import useIntersectionObserver from 'hooks/queries/useIntersectionObserver';
import { useGetPrestadoresHabilitadosInfiniteQuery } from 'hooks/queries/usePrestadoresHabilitados';
import React, { useEffect, useState } from 'react';
import {
  Prestador,
  PrestadoresHabilitadosResponse,
} from 'services/prestadoresHabilitadosService';
import { PrestadorRubro } from './types';

const PrestadoresHabilitadosListContainer: React.FC = () => {
  const LIMIT_PERPAGE_PRESTADORES_HABILITADOS = parseInt(
    process.env.REACT_APP_LIMIT_PERPAGE_PRESTADORES_HABILITADOS || '10',
    10,
  );
  const perPage = LIMIT_PERPAGE_PRESTADORES_HABILITADOS;
  const [queryText, setQueryText] = useState<string>('');
  const [provinciasSelected, setProvinciasSelected] = useState<Provincia[]>([]);
  const [queryProvincias, setQueryProvincias] = useState<string>('');
  const [rubroPrestadorSelected, setRubroPrestadorSelected] = useState<
    PrestadorRubro[]
  >([]);
  const [queryRubros, setQueryRubros] = useState<string>('');
  const search = `{ "query": "${queryText}", "idsProvincias": [${queryProvincias}], "rubrosPrestador": [${queryRubros}]}`;
  const { data, isSuccess, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useGetPrestadoresHabilitadosInfiniteQuery({ perPage, search });

  const [ref, inView] = useIntersectionObserver();

  useEffect(() => {
    if (inView && hasNextPage) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  useEffect(() => {
    if (provinciasSelected.length !== 0) {
      setQueryProvincias(
        provinciasSelected.map(provincia => `"${provincia.id}"`).toString(),
      );
    } else {
      setQueryProvincias('');
    }
  }, [provinciasSelected]);

  useEffect(() => {
    if (rubroPrestadorSelected.length > 0) {
      setQueryRubros(
        rubroPrestadorSelected.map(rubro => `"${rubro.nombre}"`).toString(),
      );
    } else {
      setQueryRubros('');
    }
  }, [rubroPrestadorSelected]);

  const prestadoresHabilitados: Prestador[] = isSuccess
    ? data.pages.map((page: PrestadoresHabilitadosResponse) => page.data).flat()
    : [];

  return (
    <PrestadoresHabilitadosListWrapper
      prestadoresHabilitados={prestadoresHabilitados}
      lastElement={ref}
      isFetchingNextPage={isFetchingNextPage}
      setQueryText={setQueryText}
      provinciasSelected={provinciasSelected}
      setProvinciasSelected={setProvinciasSelected}
      rubrosSelected={rubroPrestadorSelected}
      setRubrosSelected={setRubroPrestadorSelected}
    />
  );
};

export default PrestadoresHabilitadosListContainer;
