import { useInfiniteQuery } from '@tanstack/react-query';
import {
  PrestadoresHabilitadosResponse,
  prestadoresHabilitadosService,
} from 'services/prestadoresHabilitadosService';

const PRESTADORES_HABILITADOS_QUERY_KEY = 'prestadoreshabilitados';

const useGetPrestadoresHabilitadosInfiniteQuery = ({
  perPage,
  search,
}: {
  perPage: number;
  search: string;
}) => {
  return useInfiniteQuery<PrestadoresHabilitadosResponse, Error>(
    [PRESTADORES_HABILITADOS_QUERY_KEY, search],
    ({ pageParam = 1 }) =>
      prestadoresHabilitadosService.getPrestadoresHabilitados({
        page: pageParam as number,
        perPage,
        search,
      }),
    {
      getNextPageParam: lastPage => {
        const nextPage =
          lastPage.currentPage < lastPage.totalPages
            ? lastPage.currentPage + 1
            : undefined;
        return nextPage;
      },
    },
  );
};

export {
  useGetPrestadoresHabilitadosInfiniteQuery,
  PRESTADORES_HABILITADOS_QUERY_KEY,
};
