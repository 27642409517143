import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { ResponsivePie } from '@nivo/pie';
import { Box, Typography } from '@mui/material';
import { useCantidadInformesPorEstado } from 'hooks/queries/useCantidadInformesPorEstado';
import { CantidadInformesPorEstado } from 'services/informesService';
import styles from './PresupuestosGraficoInformesPorEstados.module.scss';
import messages from './messages';

const PresupuestosGraficoInformesPorEstados: React.FC<{
  desde: string;
  hasta: string;
  provinciasFilter: string;
  handleResultadoTabla: ({
    key,
    valor,
  }: {
    key: string;
    valor: boolean;
  }) => void;
}> = ({ desde, hasta, provinciasFilter, handleResultadoTabla }) => {
  const { formatMessage } = useIntl();
  const { data: cantidadInformesPorEstado } = useCantidadInformesPorEstado({
    desde,
    hasta,
    provinciasFilter,
  });

  useEffect(() => {
    if (cantidadInformesPorEstado) {
      const tieneDatos = cantidadInformesPorEstado.result.length > 0;
      handleResultadoTabla({
        key: 'graficoInformesPorEstados',
        valor: tieneDatos,
      });
    }
  }, [handleResultadoTabla, cantidadInformesPorEstado]);

  const cantidadInformePorEstadoChartData = cantidadInformesPorEstado
    ? cantidadInformesPorEstado.result.map(cantidadEstado => ({
        id: cantidadEstado.statusTexto,
        label: 'cantidadEstado.statusTexto',
        value: cantidadEstado.cantidad,
      }))
    : [];

  const totalInformes: number =
    cantidadInformesPorEstado?.result.reduce(
      (acc: number, cur: CantidadInformesPorEstado) => acc + cur.cantidad,
      0,
    ) ?? 0;

  return cantidadInformesPorEstado?.result.length ? (
    <Box className={styles['grafico-estados-informes-container']}>
      <Box display="flex" alignContent="center" justifyContent="center">
        <Typography variant="h6">
          {formatMessage(messages.tituloGrafico)}
        </Typography>
      </Box>
      <Box display="flex" alignContent="center" justifyContent="center">
        <Box>
          <Typography variant="caption">
            {formatMessage(messages.totalInformes)}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1">{totalInformes}</Typography>
        </Box>
      </Box>
      <ResponsivePie
        data={cantidadInformePorEstadoChartData}
        margin={{ top: 5, right: 100, bottom: 80, left: 100 }}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.2]],
        }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: 'color' }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: 'color',
          modifiers: [['darker', 2]],
        }}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: 'rgba(255, 255, 255, 0.3)',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
      />
    </Box>
  ) : null;
};

export default PresupuestosGraficoInformesPorEstados;
