import { useQuery } from '@tanstack/react-query';
import comprasService, {
  EstadisticaComprasPorMotivoAdjudicacion,
} from 'services/comprasService';

const COMPRAS_POR_MOTIVO_ADJUDICACION_QUERY_KEY =
  'estadisticaComprasPorMotivoAdjudicacion';

const useEstadisticaComprasPorAdjudicacion = ({
  desde,
  hasta,
  provinciasFilter,
}: {
  desde: string;
  hasta: string;
  provinciasFilter: string;
}) => {
  return useQuery<EstadisticaComprasPorMotivoAdjudicacion[], Error>(
    [
      COMPRAS_POR_MOTIVO_ADJUDICACION_QUERY_KEY,
      { desde, hasta, provinciasFilter },
    ],
    () =>
      comprasService.getComprasPorMotivoAdjudicacion({
        desde,
        hasta,
        provinciasFilter,
      }),
    {
      staleTime: 60 * 60 * 1000,
    },
  );
};

export {
  useEstadisticaComprasPorAdjudicacion,
  COMPRAS_POR_MOTIVO_ADJUDICACION_QUERY_KEY,
};
