import React from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { PresupuestoEstadistica } from 'services/presupuestosService';
import { calcularPorcentaje, convertirAHorasYMinutos } from 'utils/utils';
import { useIntl } from 'react-intl';
import messages from './messages';
import styles from './PresupuestosEstadisticas.module.scss';

const PresupuestosEstadisticas: React.FC<{
  estadistica: PresupuestoEstadistica;
}> = ({ estadistica }) => {
  const { formatMessage } = useIntl();
  if (!estadistica) {
    return <div>Cargando</div>;
  }

  return (
    <Box mt={2}>
      <TableContainer component={Paper}>
        <Table size="small">
          <caption>
            {`${formatMessage(messages.subtitle)} ${convertirAHorasYMinutos({
              totalMinutos: estadistica.tiempo_promedio_respuesta,
            })}`}
          </caption>
          <TableHead className={styles['th-bgcolor-green']}>
            <TableRow>
              <TableCell>
                <strong>{formatMessage(messages.title)}</strong>
              </TableCell>
              <TableCell align="right">
                {formatMessage(messages.labelCantidad)}
              </TableCell>
              <TableCell align="right">%</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow className={styles['tr-bgcolor']}>
              <TableCell>{formatMessage(messages.solicitados)}</TableCell>
              <TableCell align="right">
                {estadistica.total_presupuestado}
              </TableCell>
              <TableCell align="right">100%</TableCell>
            </TableRow>
            <TableRow className={styles['tr-bgcolor']}>
              <TableCell>{formatMessage(messages.respondidos)}</TableCell>
              <TableCell align="right">{estadistica.respondidos}</TableCell>
              <TableCell align="right">
                {calcularPorcentaje({
                  parcial: estadistica.respondidos,
                  total: estadistica.total_presupuestado,
                })}
                %
              </TableCell>
            </TableRow>
            <TableRow className={styles['tr-bgcolor']}>
              <TableCell>{formatMessage(messages.conDisponibilidad)}</TableCell>
              <TableCell align="right">
                {estadistica.respondidos_con_disponibilidad}
              </TableCell>
              <TableCell align="right">
                {calcularPorcentaje({
                  parcial: estadistica.respondidos_con_disponibilidad,
                  total: estadistica.respondidos,
                })}
                %
              </TableCell>
            </TableRow>
            <TableRow className={styles['tr-bgcolor']}>
              <TableCell>{formatMessage(messages.sinDisponibilidad)}</TableCell>
              <TableCell align="right">
                {estadistica.respondidos_sin_disponibilidad}
              </TableCell>
              <TableCell align="right">
                {calcularPorcentaje({
                  parcial: estadistica.respondidos_sin_disponibilidad,
                  total: estadistica.respondidos,
                })}
                %
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PresupuestosEstadisticas;
