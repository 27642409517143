import React from 'react';
import 'dayjs/locale/es';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { useIntl } from 'react-intl';
import messages from './messages';

const PeriodoFilterMesAnio: React.FC<{
  periodoSelected: Dayjs | null;
  setPeriodoSelected: React.Dispatch<React.SetStateAction<Dayjs | null>>;
}> = ({ periodoSelected, setPeriodoSelected }) => {
  const { formatMessage } = useIntl();
  const SHOW_MIN_DATE = dayjs(
    new Date(process.env.REACTAPP_SHOW_MIN_DATE || ''),
  );
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="Es">
      <DatePicker
        label={formatMessage(messages.titleDatePickerPeriodo)}
        views={['month', 'year']}
        openTo="month"
        value={periodoSelected}
        onChange={setPeriodoSelected}
        closeOnSelect
        slotProps={{ actionBar: { actions: ['clear'] } }}
        maxDate={dayjs(new Date())}
        minDate={SHOW_MIN_DATE}
        yearsPerRow={3}
      />
    </LocalizationProvider>
  );
};

export default PeriodoFilterMesAnio;
