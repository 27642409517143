import { removeItem } from 'services/sessionStorageService';
import { RequestMethods, request } from 'clients/innboxes1';
import { Aseguradora, AseguradoraResponse } from './types';

const BASE_URL = process.env.REACT_APP_BASE_URL ?? '';
const ROOT_URL = process.env.REACT_APP_ROOT_URL ?? '';
const KEY_ASEGURADORA_LOGGED =
  process.env.REACT_APP_KEY_ASEGURADORA_LOGGED ?? '';
const ASEGURADORA_URL = process.env.REACT_APP_URL_ASEGURADORAS;

const getAseguradora = async (): Promise<Aseguradora> => {
  const response = await fetch(`${BASE_URL}/api/aseguradora`, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });

  if (!response.ok) {
    if (response.status === 401) {
      removeItem(KEY_ASEGURADORA_LOGGED);
      window.location.replace(`${ROOT_URL}`);
    }
    throw new Error(`Status:${response.status}. ${response.statusText}`);
  }

  // TODO: Mover a client y mejorar tipos
  const data = <AseguradoraResponse>await response.json();

  const id = Object.keys(data)[0];
  const aseguradora = data[id];

  return aseguradora;
};

const cambiarEstadoPrestador = async ({
  prestadorId,
  textoComentario,
  estadoActual,
}: {
  prestadorId: string;
  textoComentario: string;
  estadoActual: boolean;
}): Promise<void> => {
  const endpointCambiarEstadoPrestador = 'cambiarestadoprestador';

  const cambiarEstadoPrestadorBody = {
    prestadorId,
    textoComentario,
    estadoActual,
  };

  await request({
    method: RequestMethods.put,
    baseUrl: ASEGURADORA_URL,
    endpoint: endpointCambiarEstadoPrestador,
    body: JSON.stringify(cambiarEstadoPrestadorBody),
  });
};

export default { getAseguradora, cambiarEstadoPrestador };
