import { defineMessages } from 'react-intl';

export default defineMessages({
  agregarObservacion: {
    id: 'Informe.agregarObservacion',
    description: 'Texto para el título del dialogo agregar observacion',
    defaultMessage: 'Agregar Observación',
  },
  observacion: {
    id: 'Informe.observacion',
    description: 'Texto para el textfield de la observación',
    defaultMessage: 'Observación',
  },
  enviar: {
    id: 'Informe.enviar',
    description: 'Texto para el boton para enviar',
    defaultMessage: 'Enviar',
  },
  subirAdjunto: {
    id: 'Informe.subirAdjunto',
    description: 'Texto para el botón Subir Adjunto',
    defaultMessage: 'Subir Adjunto',
  },
  seleccionarArchivo: {
    id: 'Informe.seleccionarArchivo',
    description: 'Leyenda para el botón de selección de archivo',
    defaultMessage: 'Seleccionar archivo',
  },
  vistaPrevia: {
    id: 'Informe.vistaPrevia',
    description: 'Etiqueta para la vista previa del archivo',
    defaultMessage: 'Vista previa',
  },
  descargar: {
    id: 'Informe.descargar',
    description: 'Etiqueta para el botón descargar',
    defaultMessage: 'Descargar',
  },
});
