import { defineMessages } from 'react-intl';

export default defineMessages({
  formPatenteTitulo: {
    id: 'LoginForm.formPatenteTitulo',
    description:
      'Leyenda para el titulo del formulario de busqueda por patente',
    defaultMessage: 'Seguimiento de su caso',
  },
  formPatenteSubtitulo: {
    id: 'LoginForm.formPatenteSubtitulo',
    description: 'Leyenda para formulario de busqueda por patente',
    defaultMessage: 'Ingrese su patente',
  },
  textSearchNotFound: {
    id: 'LoginForm.textSearchNotFound',
    description: 'Mensaje cuando no se encuentran registros',
    defaultMessage: 'No se encontraron casos con la patente ingresada.',
  },
  textErrorSearch: {
    id: 'LoginForm.textErrorSearch',
    description: 'Mensaje error default',
    defaultMessage:
      'Ocurrió un error al buscar la patente. Intente nuevamente.',
  },
  inputPatenteHelperText: {
    id: 'LoginForm.inputPatenteHelperText',
    description: 'Texto helpertext',
    defaultMessage:
      'Ingrese la patente solo numeros y letras, sin espacios. Ej: ABC123',
  },
  inputPatente: {
    id: 'LoginForm.inputPatente',
    description: 'Label input patente',
    defaultMessage: 'Patente',
  },
  buttonBuscar: {
    id: 'LoginForm.buttonBuscar',
    description: 'Texto boton buscar',
    defaultMessage: 'Buscar',
  },
  formLoginHeader: {
    id: 'LoginForm.formLoginHeader',
    description: 'Header para el card que contiene el login',
    defaultMessage: 'Iniciar Sesión',
  },
  titulo: {
    id: 'LoginForm.title',
    description: 'Titulo para login',
    defaultMessage: 'Inicio de Sesión',
  },
  subtitulo: {
    id: 'LoginForm.subtitle',
    description: 'Leyenda para el login',
    defaultMessage: 'Ingrese su email y contraseña para iniciar sesión',
  },
  telefono1: {
    id: 'LoginForm.telefono1',
    description: 'Teléfono',
    defaultMessage: '0810-345 0433',
  },
  telefono2: {
    id: 'LoginForm.telefono2',
    description: 'Teléfono',
    defaultMessage: '011-4016 2500',
  },
  mail: {
    id: 'LoginForm.mail',
    description: 'Mail',
    defaultMessage: 'info@appsolut.us',
  },
  nombreEmpresa: {
    id: 'LoginForm.nombreEmpresa',
    description: 'Nombre de la empresa',
    defaultMessage: 'Appsolut Mobility S.A.S.',
  },
  inputEmail: {
    id: 'LoginForm.input.email',
    description: 'Label input email',
    defaultMessage: 'Email',
  },
  inputPassword: {
    id: 'LoginForm.input.password',
    description: 'Label input password',
    defaultMessage: 'Contraseña',
  },
  linkRecuperarClave: {
    id: 'LoginForm.link.recuperarClave',
    description: 'Texto para el link que recupera la clave',
    defaultMessage: 'Olvide mi clave',
  },
  buttonLogin: {
    id: 'LoginForm.button.login',
    description: 'Texto boton login',
    defaultMessage: 'Ingresar',
  },
  imgLogoTitle: {
    id: 'LoginForm.img.logo.title',
    description: '',
    defaultMessage: 'Logo Appsolut',
  },
  textErrorResponse: {
    id: 'LoginForm.textErrorResponse',
    description: 'Texto para error del response login',
    defaultMessage: 'Error de usuario o clave',
  },
  textErrorLogin: {
    id: 'LoginForm.textErrorLogin',
    description: 'Texto para error del login',
    defaultMessage:
      'Ha ocurrido un error al intentar ingresar. Intente nuevamente.',
  },
});
