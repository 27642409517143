import { defineMessages } from 'react-intl';

export default defineMessages({
  titleList: {
    id: 'InformesListView.titleList',
    description: 'Titulo del listado',
    defaultMessage: 'Listado de informes',
  },
  inputBasePlaceholder: {
    id: 'InformesListView.inputBasePlaceholder',
    description: 'Placeholder para el search',
    defaultMessage: 'Buscar...',
  },
  inputBaseLabel: {
    id: 'InformesListView.inputBaseLabel',
    description: 'Label para el search',
    defaultMessage: 'Búsqueda',
  },
  buttonBuscarTexto: {
    id: 'InformesListView.buttonBuscarTexto',
    description: 'Boton para aplicar la busqueda del texto',
    defaultMessage: 'Buscar',
  },
  buttonReport: {
    id: 'InformesListView.buttonReport',
    description: 'Boton para acceder a la vista del reporte',
    defaultMessage: 'Reporte',
  },
  buttonReportTerceros: {
    id: 'InformesListView.buttonReportTerceros',
    description: 'Boton para acceder a la vista del reporte de terceros',
    defaultMessage: 'Terceros',
  },
  labelId: {
    id: 'InformesListView.labelId',
    description: 'Id',
    defaultMessage: 'ID',
  },
  labelCodigo: {
    id: 'InformesListView.labelCodigo',
    description: 'Codigo',
    defaultMessage: 'Codigo',
  },
  labelSiniestro: {
    id: 'InformesListView.labelSiniestro',
    description: 'Numero de Siniestro de la aseguradora',
    defaultMessage: 'Siniestro',
  },
  labelAseguradora: {
    id: 'InformesListView.labelAseguradora',
    description: 'Nombre Aseguradora',
    defaultMessage: 'Aseguradora',
  },
  labelAsignado: {
    id: 'InformesListView.labelAsignado',
    description: 'Analista Asignado al informe',
    defaultMessage: 'Asignado',
  },
  labelAsegurado: {
    id: 'InformesListView.labelAsegurado',
    description: 'Apellido y nombre del asegurado',
    defaultMessage: 'Asegurado',
  },
  labelPatente: {
    id: 'InformesListView.labelPatente',
    description: 'Patente del Vehículo',
    defaultMessage: 'Patente',
  },
  labelVehiculo: {
    id: 'InformesListView.labelVehiculo',
    description: 'Modelo del vehículo',
    defaultMessage: 'Vehículo',
  },
  labelProvincia: {
    id: 'InformesListView.labelProvincia',
    description: 'Provincia',
    defaultMessage: 'Provincia',
  },
  labelLocalidad: {
    id: 'InformesListView.labelLocalidad',
    description: 'Localidad',
    defaultMessage: 'Localidad',
  },
  labelStatus: {
    id: 'InformesListView.labelStatus',
    description: 'Estado',
    defaultMessage: 'Estado',
  },
  labelNoFiltered: {
    id: 'InformesListView.labelNoFiltered',
    description: 'Mensaje que no tiene filtros el listado de informes',
    defaultMessage: 'Debe aplicar un filtro para visualizar los informes',
  },
  labelNoResults: {
    id: 'InformesListView.labelNoResults',
    description: 'Mensaje cuando el listado de informes es vacío',
    defaultMessage: 'No existen informes con los filtros aplicados',
  },
  labelFechaIngreso: {
    id: 'InformesListView.labelFechaIngreso',
    description: 'Fecha de Ingreso del informe',
    defaultMessage: 'Fecha Ingreso',
  },
});
