import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Box,
  CardMedia,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useIntl } from 'react-intl';
import authService from 'services/authService';
import messages from './messages';
import styles from './DrawerNav.module.scss';

const BASE_URL = process.env.REACT_APP_BASE_URL ?? '';

const navItems = [
  { title: 'Dashboard', path: '/dashboard' },
  { title: 'Informes', path: '/informes' },
  { title: 'Prestadores', path: '/prestadoreshabilitados' },
  { title: 'Reportes', path: `${BASE_URL}/appV2/Reportes`, anchor: true },
  { title: 'Salir', path: '/' },
];

const DrawerNav: React.FC<{
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}> = ({ mobileOpen, handleDrawerToggle }) => {
  const { formatMessage } = useIntl();

  const handleClickSalir = async () => {
    await authService.logout();
    if (mobileOpen) {
      handleDrawerToggle();
    }
  };

  return (
    <Box
      component="nav"
      aria-label="mailbox folders"
      className={styles['box-nav']}
    >
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        className={styles['nav-drawer-temporary']}
      >
        <Toolbar>
          <CardMedia
            component="img"
            image="./logo_sekurit.png"
            aria-label={formatMessage(messages.titleDrawerNav)}
          />
        </Toolbar>
        <Divider />
        <List>
          {navItems.map(item =>
            item.anchor ? (
              <ListItem
                key={item.title}
                className={styles['nav-button-anchor']}
                component="a"
                href={item.path}
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon />
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItemButton>
              </ListItem>
            ) : (
              <ListItem key={item.title} disablePadding>
                <ListItemButton
                  component={NavLink}
                  to={item.path}
                  onClick={
                    item.title === 'Salir'
                      ? handleClickSalir
                      : handleDrawerToggle
                  }
                >
                  <ListItemIcon>
                    <KeyboardArrowRightIcon />
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItemButton>
              </ListItem>
            ),
          )}
        </List>
      </Drawer>
      <Drawer variant="permanent" className={styles['nav-drawer-permanent']}>
        <Toolbar>
          <CardMedia
            component="img"
            image="./logo_sekurit.png"
            aria-label={formatMessage(messages.titleDrawerNav)}
          />
        </Toolbar>
        <Divider />
        <List>
          {navItems.map(item =>
            item.anchor ? (
              <ListItem
                key={item.title}
                className={styles['nav-button-anchor']}
                component="a"
                href={item.path}
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon>
                    <KeyboardArrowRightIcon />
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItemButton>
              </ListItem>
            ) : (
              <ListItem key={item.title} disablePadding>
                <ListItemButton
                  component={NavLink}
                  to={item.path}
                  onClick={
                    item.title === 'Salir' ? handleClickSalir : undefined
                  }
                >
                  <ListItemIcon>
                    <KeyboardArrowRightIcon />
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItemButton>
              </ListItem>
            ),
          )}
        </List>
      </Drawer>
    </Box>
  );
};

export default DrawerNav;
