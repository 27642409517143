import RequestMethods from './types';

const BASE_URL = process.env.REACT_APP_BASE_URL ?? '';
const ROOT_URL = process.env.REACT_APP_ROOT_URL ?? '';
const API_TOKEN = process.env.REACT_APP_API_TOKEN ?? '';

const request = async ({
  method,
  baseUrl = BASE_URL,
  endpoint,
  body,
  includeCredentials = true,
  includeToken = false,
  defaultContentType = true,
  defaultAccept = true,
}: {
  method: RequestMethods;
  baseUrl?: string;
  endpoint: string;
  body?: BodyInit;
  includeCredentials?: boolean;
  includeToken?: boolean;
  defaultContentType?: boolean;
  defaultAccept?: boolean;
}): Promise<unknown> => {
  const CONTENT_TYPE_HEADER = 'Content-Type';

  const response = await fetch(`${baseUrl}/${endpoint}`, {
    method,
    credentials: includeCredentials ? 'include' : undefined,
    headers: {
      Authorization: includeToken ? `Bearer ${API_TOKEN}` : '',
      ...(defaultContentType ? { 'Content-Type': 'application/json' } : {}),
      ...(defaultAccept ? { Accept: 'application/json' } : {}),
    },
    body,
  });

  if (!response.ok) {
    if (response.status === 401) {
      window.location.replace(`${ROOT_URL}`);
    }
    throw new Error(`Status:${response.status}. ${response.statusText}`);
  }

  const contentType = response.headers.get(CONTENT_TYPE_HEADER) || '';

  return contentType.includes('application/json')
    ? response.json()
    : response.text();
};

export default request;
