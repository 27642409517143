import { useQuery } from '@tanstack/react-query';
import {
  presupuestosService,
  EstadisticaPromedioPrecios,
} from 'services/presupuestosService';

const PROMEDIO_PRECIOS_QUERY_KEY = 'estadisticaPromedioPrecios';

const useGetEstadisticaPromedioPrecios = ({
  desde,
  hasta,
  provinciasFilter,
}: {
  desde: string;
  hasta: string;
  provinciasFilter: string;
}) => {
  return useQuery<EstadisticaPromedioPrecios[], Error>(
    [PROMEDIO_PRECIOS_QUERY_KEY, { desde, hasta, provinciasFilter }],
    () =>
      presupuestosService.getPromedioPrecios({
        desde,
        hasta,
        provinciasFilter,
      }),
    {
      staleTime: 60 * 60 * 1000,
    },
  );
};

export { useGetEstadisticaPromedioPrecios, PROMEDIO_PRECIOS_QUERY_KEY };
