import React, { useEffect /* useState */ } from 'react';

const BASE_URL = process.env.REACT_APP_BASE_URL ?? '';

const Reportes: React.FC = () => {
  useEffect(() => {
    const reportesURL = `${BASE_URL}/appV2/Reportes`;
    window.location.href = reportesURL;
  }, []);

  return null;
};

export default Reportes;
