// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PresupuestosPromedioPrecios_th-bgcolor-purple__cYYov{background-color:#b39ddb}.PresupuestosPromedioPrecios_tr-bgcolor__kIYhn:nth-child(odd){background-color:#ede7f6}", "",{"version":3,"sources":["webpack://./src/components/Dashboard/PresupuestosPromedioPrecios/PresupuestosPromedioPrecios.module.scss"],"names":[],"mappings":"AAAA,sDACC,wBAAA,CAED,8DACC,wBAAA","sourcesContent":[".th-bgcolor-purple {\n\tbackground-color: #b39ddb;\n}\n.tr-bgcolor:nth-child(odd) {\t\n\tbackground-color: #ede7f6;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"th-bgcolor-purple": "PresupuestosPromedioPrecios_th-bgcolor-purple__cYYov",
	"tr-bgcolor": "PresupuestosPromedioPrecios_tr-bgcolor__kIYhn"
};
export default ___CSS_LOADER_EXPORT___;
