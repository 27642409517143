import { defineMessages } from 'react-intl';

export default defineMessages({
  tituloGrafico: {
    id: 'PresupuestosGraficoInformesPorEstados.tituloGrafico',
    description: 'Título para el gráfico',
    defaultMessage: 'Estado de Informes',
  },
  totalInformes: {
    id: 'PresupuestosGraficoInformesPorEstados.totalInformes',
    description: 'Total de informes',
    defaultMessage: 'Total de informes ingresados: ',
  },
});
