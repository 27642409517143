import { useMutation, useQueryClient } from '@tanstack/react-query';
import { PRESTADORES_HABILITADOS_QUERY_KEY } from 'hooks/queries/usePrestadoresHabilitados/usePrestadoresHabilitados';
import aseguradoraService from 'services/aseguradoraService/aseguradoraService';

const CAMBIAR_ESTADO_PRESTADOR_KEY = 'cambiarEstadoPrestador';

const useCambiarEstadoPrestador = ({
  prestadorId,
}: {
  prestadorId: string;
}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      textoComentario,
      estadoActual,
    }: {
      textoComentario: string;
      estadoActual: boolean;
    }) =>
      aseguradoraService.cambiarEstadoPrestador({
        prestadorId,
        textoComentario,
        estadoActual,
      }),
    mutationKey: [CAMBIAR_ESTADO_PRESTADOR_KEY, prestadorId],
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: [PRESTADORES_HABILITADOS_QUERY_KEY],
      });
    },
  });
};

export { useCambiarEstadoPrestador, CAMBIAR_ESTADO_PRESTADOR_KEY };
