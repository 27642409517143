import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/es';
import PresupuestosEstadisticasContainer from 'components/Dashboard/PresupuestosEstadisticasContainer';
import PresupuestosRankingPrestadoresContainer from 'components/Dashboard/PresupuestosRankingPrestadoresContainer';
import PresupuestosPromedioPreciosContainer from 'components/Dashboard/PresupuestosPromedioPreciosContainer';
import PresupuestosGraficoInformesPorEstados from 'components/Dashboard/PresupuestosGraficoInformesPorEstados';
import Loading from 'components/Loading';
import PresupuestosGraficoCasosFinalizados from 'components/Dashboard/PresupuestosGraficoCasosFinalizados';
import PresupuestosGraficoTiempoResolucion from 'components/Dashboard/PresupuestosGraficoTiempoResolucion';
import ComprasPorMotivoAdjudicacionContainer from 'components/Dashboard/ComprasPorMotivoAdjudicacionContainer';
import ProvinciasFiltereMultiselect, {
  Provincia,
} from 'components/ProvinciasFilterMultiselect';
import provincias from 'components/ProvinciasFilterMultiselect/provincias.json';
import NoResults from 'components/NoResults';
import messages from './messages';
import { ResultadosTablas } from './types';

const Dashboard: React.FC = () => {
  const { formatMessage } = useIntl();

  const currentDate = new Date();
  const currentDayStr = dayjs(currentDate).toISOString().substring(0, 10);

  const [desde, setDesde] = useState<Dayjs | null>(dayjs(currentDate));
  const [hasta, setHasta] = useState<Dayjs | null>(dayjs(currentDate));
  const [minDate, setMinDate] = useState<Dayjs>(dayjs(currentDate));
  const [desdeStr, setDesdeStr] = useState<string>(currentDayStr);
  const [hastaStr, setHastaStr] = useState<string>(currentDayStr);
  const [provinciasSelected, setProvinciasSelected] = useState<Provincia[]>([]);
  const [queryProvincias, setQueryProvincias] = useState<string>('');

  const [resultados, setResultados] = useState<ResultadosTablas>({
    presupuestosEstadisticas: true,
    estadisticasPromedioPrecios: true,
    presupuestosRankingPrestadores: true,
    comprasPorMotivoAdjudicacion: true,
    graficoInformesPorEstados: true,
    graficoTiempoResolucion: true,
    graficoCasosFinalizados: true,
  });

  useEffect(() => {
    if (provinciasSelected.length !== 0) {
      setQueryProvincias(
        provinciasSelected
          .map(provincia => `idsProvincias=${provincia.id}`)
          .join('&'),
      );
    } else {
      setQueryProvincias('');
    }
  }, [provinciasSelected]);

  const handleClickSearch = () => {
    if (desde && hasta) {
      setDesdeStr(desde.toISOString().substring(0, 10));
      setHastaStr(hasta.toISOString().substring(0, 10));
    }
  };

  const handleChangeDesde = (newDate: Dayjs | null): void => {
    if (newDate) {
      setDesde(newDate);
      setMinDate(newDate);
    }
  };

  const handleChangeHasta = (newDate: Dayjs | null): void => {
    if (newDate) {
      setHasta(newDate);
    }
  };

  const handleChangeResultados = useCallback(
    ({ key, valor }: { key: string; valor: boolean }) => {
      setResultados(prev => ({ ...prev, [key]: valor }));
    },
    [],
  );

  const sinDatosEnLasTablas = Object.values(resultados).every(
    value => value === false,
  );

  return (
    <>
      <Typography variant="h6" align="left">
        <strong>{formatMessage(messages.titlePage)}</strong>
      </Typography>
      <Card>
        <CardContent>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              columnSpacing={1}
              rowSpacing={1}
            >
              <Grid item xs={12} md={5}>
                <ProvinciasFiltereMultiselect
                  provincias={provincias}
                  provinciasSelected={provinciasSelected}
                  setProvinciasSelected={setProvinciasSelected}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <DatePicker
                  label={formatMessage(messages.labelFechaDesde)}
                  value={desde}
                  onChange={handleChangeDesde}
                  closeOnSelect
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </Grid>
              <Grid item xs={6} md={3}>
                <DatePicker
                  label={formatMessage(messages.labelFechaDesde)}
                  value={hasta}
                  minDate={minDate}
                  onChange={handleChangeHasta}
                  closeOnSelect
                  slotProps={{ textField: { fullWidth: true } }}
                />
              </Grid>
              <Grid item xs={12} md={1} alignItems="center">
                <Button
                  variant="contained"
                  fullWidth
                  onClick={handleClickSearch}
                >
                  {formatMessage(messages.buttonBuscar)}
                </Button>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </CardContent>
      </Card>
      {sinDatosEnLasTablas && (
        <NoResults
          title={formatMessage(messages.titlePage)}
          message={formatMessage(messages.textNoResults)}
        />
      )}

      <Grid container columnSpacing={2} marginY={1}>
        <Grid item xs={12} sm={6} lg={4}>
          <Suspense fallback={<Loading />}>
            <PresupuestosEstadisticasContainer
              desde={desdeStr}
              hasta={hastaStr}
              provinciasFilter={queryProvincias}
              handleResultadoTabla={handleChangeResultados}
            />
          </Suspense>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Suspense fallback={<Loading />}>
            <PresupuestosPromedioPreciosContainer
              desde={desdeStr}
              hasta={hastaStr}
              provinciasFilter={queryProvincias}
              handleResultadoTabla={handleChangeResultados}
            />
          </Suspense>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Suspense fallback={<Loading />}>
            <ComprasPorMotivoAdjudicacionContainer
              desde={desdeStr}
              hasta={hastaStr}
              provinciasFilter={queryProvincias}
              handleResultadoTabla={handleChangeResultados}
            />
          </Suspense>
        </Grid>
      </Grid>
      <Grid container columnSpacing={2} marginTop={2}>
        <Grid item xs={12} sm={6} lg={4}>
          <Suspense fallback={<Loading />}>
            <PresupuestosGraficoInformesPorEstados
              desde={desdeStr}
              hasta={hastaStr}
              provinciasFilter={queryProvincias}
              handleResultadoTabla={handleChangeResultados}
            />
          </Suspense>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Suspense fallback={<Loading />}>
            <PresupuestosGraficoCasosFinalizados
              desde={desdeStr}
              hasta={hastaStr}
              provinciasFilter={queryProvincias}
              handleResultadoTabla={handleChangeResultados}
            />
          </Suspense>
        </Grid>
        <Grid item xs={12} sm={6} lg={4}>
          <Suspense fallback={<Loading />}>
            <PresupuestosGraficoTiempoResolucion
              desde={desdeStr}
              hasta={hastaStr}
              provinciasFilter={queryProvincias}
              handleResultadoTabla={handleChangeResultados}
            />
          </Suspense>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Suspense fallback={<Loading />}>
            <PresupuestosRankingPrestadoresContainer
              desde={desdeStr}
              hasta={hastaStr}
              provinciasFilter={queryProvincias}
              handleResultadoTabla={handleChangeResultados}
            />
          </Suspense>
        </Grid>
      </Grid>
    </>
  );
};

export default Dashboard;
