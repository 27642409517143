import { RequestMethods, request } from 'clients/innboxes1';
import { PrestadoresHabilitadosResponse } from './types';

const URL_API_ASEGURADORAS = process.env.REACT_APP_URL_ASEGURADORAS;

const getPrestadoresHabilitados = async ({
  page = 1,
  perPage = 10,
  search = '',
}: {
  page: number;
  perPage: number;
  search: string;
}): Promise<PrestadoresHabilitadosResponse> => {
  const endpointPrestadoresHabilitados = `prestadoreshabilitados?search=${search}&page=${page}&perPage=${perPage}`;
  const data = <PrestadoresHabilitadosResponse>await request({
    method: RequestMethods.get,
    baseUrl: URL_API_ASEGURADORAS,
    endpoint: endpointPrestadoresHabilitados,
  });

  return data;
};

export default { getPrestadoresHabilitados };
