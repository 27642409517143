import {
  calcularPorcentaje,
  convertirAHorasYMinutos,
  convertirformatoMoneda,
} from './utils';

export default {
  calcularPorcentaje,
  convertirAHorasYMinutos,
  convertirformatoMoneda,
};
