declare global {
  interface Window {
    _mfq: string[];
  }
}

const iniciarMouseFlow = (urlMouseflow: string) => {
  if (!window._mfq) {
    window._mfq = [];
  }
  const mf = document.createElement('script');
  mf.type = 'text/javascript';
  mf.defer = true;
  mf.src = urlMouseflow;
  document.getElementsByTagName('head')[0].appendChild(mf);
};

export { iniciarMouseFlow };
