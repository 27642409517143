import { defineMessages } from 'react-intl';

export default defineMessages({
  titleList: {
    id: 'PrestadoresHabilitadosListWrapper.titleList',
    description: 'Titulo del listado',
    defaultMessage: 'Prestadores Habilitados',
  },
  labelNoResults: {
    id: 'PrestadoresHabilitadosListWrapper.labelNoResults',
    description:
      'Mensaje cuando el listado de prestadores habilitados no tiene resultados',
    defaultMessage: 'No existen prestadores habilitados',
  },
  inputBasePlaceholder: {
    id: 'PrestadoresHabilitadosListWrapper.inputBasePlaceholder',
    description: 'Placeholder para el search',
    defaultMessage: 'Buscar...',
  },
  inputBaseLabel: {
    id: 'PrestadoresHabilitadosListWrapper.inputBaseLabel',
    description: 'Label para el search',
    defaultMessage: 'Búsqueda',
  },
  buttonBuscarTexto: {
    id: 'PrestadoresHabilitadosListWrapper.buttonBuscarTexto',
    description: 'Boton para aplicar la busqueda del texto',
    defaultMessage: 'Buscar',
  },
  labelRubroTexto: {
    id: 'PrestadoresHabilitadosListWrapper.labelRubroTexto',
    description: 'Titulo filtro multiple de Rubros',
    defaultMessage: 'Selección Rubro',
  },
  inputRubroPlaceholder: {
    id: 'PrestadoresHabilitadosListWrapper.inputRubroPlaceholder',
    description: 'Placeholder para el filtro de rubros',
    defaultMessage: 'Rubro',
  },
});
