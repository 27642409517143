import React from 'react';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { useIntl } from 'react-intl';
import messages from './messages';

const Loading: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <Stack mt={15} spacing={2} alignItems="center" justifyContent="center">
      <CircularProgress />
      <Typography variant="subtitle2">
        {formatMessage(messages.loadingText)}
      </Typography>
    </Stack>
  );
};

export default Loading;
