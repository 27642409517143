import { defineMessages } from 'react-intl';

export default defineMessages({
  labelEstadoInformes: {
    id: 'EstadosInformeFilterMultiSelect.labelEstadoInformes',
    description: 'Estado de los informes',
    defaultMessage: 'Selección Etapas',
  },
  estadosInformePlaceholder: {
    id: 'EstadosInformeFilterMultiSelect.estadosInformePlaceholder',
    description: 'Estado de los informes',
    defaultMessage: 'Etapas...',
  },
});
