import { useMutation, useQueryClient } from '@tanstack/react-query';
import { HTML_INFORME_QUERY_KEY } from 'hooks/queries/useHtmlInforme';
import { informesService } from 'services/informesService';

const AGREGAR_COMENTARIO_A_INFORME_KEY = 'agregarComentarioAInforme';

const useAgregarComentarioAInforme = ({ informeId }: { informeId: string }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ textoComentario }: { textoComentario: string }) =>
      informesService.agregarComentarioAInforme({
        informeId,
        textoComentario,
      }),
    mutationKey: [AGREGAR_COMENTARIO_A_INFORME_KEY, informeId],
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: [HTML_INFORME_QUERY_KEY, informeId],
      });
    },
  });
};

export { useAgregarComentarioAInforme, AGREGAR_COMENTARIO_A_INFORME_KEY };
