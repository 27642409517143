// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ComprasPorMotivoAdjudicacion_th-color__o6GXI{background-color:#ff7043}.ComprasPorMotivoAdjudicacion_tr-total__l21D2{background-color:#ffccbc}.ComprasPorMotivoAdjudicacion_tr-total__l21D2 .ComprasPorMotivoAdjudicacion_tc-col__hsWP7{font-weight:bold}.ComprasPorMotivoAdjudicacion_tc-first-col__fsrHY{padding-left:2.5rem;font-size:.75rem}", "",{"version":3,"sources":["webpack://./src/components/Dashboard/ComprasPorMotivoAdjudicacion/ComprasPorMotivoAdjudicacion.module.scss"],"names":[],"mappings":"AAAA,8CACC,wBAAA,CAED,8CACC,wBAAA,CAEA,0FACC,gBAAA,CAGF,kDACC,mBAAA,CACA,gBAAA","sourcesContent":[".th-color {\n\tbackground-color: #ff7043;\n}\n.tr-total {\n\tbackground-color: #ffccbc;\n\n\t.tc-col {\t\n\t\tfont-weight:bold;\n\t}\n}\n.tc-first-col {\n\tpadding-left: 2.5rem;\n\tfont-size: .75rem;\t\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"th-color": "ComprasPorMotivoAdjudicacion_th-color__o6GXI",
	"tr-total": "ComprasPorMotivoAdjudicacion_tr-total__l21D2",
	"tc-col": "ComprasPorMotivoAdjudicacion_tc-col__hsWP7",
	"tc-first-col": "ComprasPorMotivoAdjudicacion_tc-first-col__fsrHY"
};
export default ___CSS_LOADER_EXPORT___;
