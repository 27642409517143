import {
  Alert,
  AlertTitle,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ItemField from 'components/ItemField';
import React, { Fragment, useState } from 'react';
import { Prestador } from 'services/prestadoresHabilitadosService';
import { useIntl } from 'react-intl';
import Loading from 'components/Loading';
import { useCambiarEstadoPrestador } from 'hooks/mutations/useCambiarEstadoPrestador';
import styles from './PrestadoresHabilitadosListView.module.scss';
import messages from './messages';

const PrestadoresHabilitadosListView: React.FC<{
  prestadoresHabilitados: Prestador[];
  lastElement: React.RefObject<HTMLInputElement>;
  isFetchingNextPage: boolean;
}> = ({ prestadoresHabilitados, lastElement, isFetchingNextPage }) => {
  const { formatMessage } = useIntl();

  const [prestadorId, setPrestadorId] = useState<string>('');
  const [estadoActual, setEstadoActual] = useState<boolean>(false);

  const [agregarComentarioModalOpen, setAgregarComentarioModalOpen] =
    useState<boolean>(false);
  const [textoComentario, setTextoComentario] = useState<string>();

  const [
    errorCambioEstadoPrestadorModalOpen,
    setErrorCambioEstadoPrestadorModalOpen,
  ] = useState<boolean>(false);

  const cambiarEstadoPrestador = useCambiarEstadoPrestador({
    prestadorId,
  });

  const handleClose = () => {
    setAgregarComentarioModalOpen(false);
    setTextoComentario(undefined);
    setPrestadorId('');
    setErrorCambioEstadoPrestadorModalOpen(false);
  };

  const handleAgregarComentario = () => {
    if (!textoComentario) {
      return;
    }
    try {
      if (prestadorId === '') {
        throw new Error('No se detectó un prestador para cambiar su estado');
      }
      cambiarEstadoPrestador.mutate(
        { textoComentario, estadoActual },
        {
          onSuccess: () => {
            setAgregarComentarioModalOpen(false);
            setTextoComentario(undefined);
            setPrestadorId('');
          },
          onError: () => {
            setAgregarComentarioModalOpen(false);
            setTextoComentario(undefined);
            setPrestadorId('');
            setErrorCambioEstadoPrestadorModalOpen(true);
          },
        },
      );
    } catch (error) {
      setErrorCambioEstadoPrestadorModalOpen(true);
    }
  };

  const handleSwitch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPrestadorId(event.target.id);
    setEstadoActual(!event.target.checked);
    setAgregarComentarioModalOpen(true);
  };

  return (
    <>
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        rowSpacing={1}
        marginTop={0.5}
      >
        {prestadoresHabilitados.map(prestador => (
          <Fragment key={prestador.id}>
            <Grid item xs={9} sm={9} md={10} container>
              <ItemField
                label={formatMessage(messages.labelRazonSocial)}
                value={prestador.razon_social}
                xs={6}
                sm={4}
                md={4}
              />
              <ItemField
                label={formatMessage(messages.labelNombreFantasia)}
                value={prestador.nombre_fantasia}
                xs={6}
                sm={4}
                md={4}
              />
              <ItemField
                label={formatMessage(messages.labelDireccion)}
                value={prestador.direccion || ''}
                xs={6}
                sm={4}
                md={4}
              />
              <ItemField
                label={formatMessage(messages.labelProvincia)}
                value={prestador.provincia || ''}
                xs={6}
                sm={4}
                md={4}
              />
              <ItemField
                label={formatMessage(messages.labelCantidadSucursales)}
                value={prestador.cantidad_sucursales?.toString() || ''}
                xs={6}
                sm={3}
                md={4}
              />
              <ItemField
                label={formatMessage(messages.labelTipoPrestador)}
                value={prestador.tp_prestador || ''}
                xs={6}
                sm={3}
                md={4}
              />
              <ItemField
                label={formatMessage(messages.labelPresencia)}
                value={prestador.presencia || ''}
                xs={12}
                sm={12}
                md={12}
              />
            </Grid>
            <Grid item>
              <FormControlLabel
                className={styles['switch-row']}
                control={
                  <Switch
                    id={prestador.id}
                    checked={prestador.habilitado}
                    onChange={handleSwitch}
                  />
                }
                label={formatMessage(messages.labelEstadoPrestador)}
                labelPlacement="top"
              />
            </Grid>
            <Grid item xs={12}>
              <Divider className={styles['divider-row']} />
            </Grid>
          </Fragment>
        ))}
        <div className="app">
          <div ref={lastElement} />
          {isFetchingNextPage && <Loading />}
        </div>
      </Grid>
      <Dialog
        PaperProps={{ className: styles['dialog-container'] }}
        open={agregarComentarioModalOpen}
        onClose={handleClose}
      >
        <DialogTitle>
          {formatMessage(messages.labelTituloComentario)}
        </DialogTitle>
        <IconButton
          className={styles['iconbutton-close']}
          aria-label="close"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        <Box className={styles['dialog-content-container']}>
          <Box textAlign="center">
            <TextField
              fullWidth
              id="nuevo-comentario"
              label={formatMessage(messages.labelTextoComentario)}
              multiline
              minRows={5}
              placeholder={formatMessage(messages.labelPlaceHolderComentario)}
              value={textoComentario}
              onChange={event => {
                setTextoComentario(event.target?.value);
              }}
            />
          </Box>
          <Box
            mt={2}
            textAlign="center"
            className={styles['comentario-button-container']}
          >
            <Button
              onClick={handleAgregarComentario}
              disabled={!textoComentario}
              variant="outlined"
            >
              {cambiarEstadoPrestador?.isLoading ? (
                <CircularProgress />
              ) : (
                formatMessage(messages.labelEnviarComentario)
              )}
            </Button>
          </Box>
        </Box>
      </Dialog>
      <Dialog open={errorCambioEstadoPrestadorModalOpen}>
        <Alert
          className={styles['alert-error-cambio-estado-prestador']}
          variant="outlined"
          severity="error"
          onClose={() => {
            setErrorCambioEstadoPrestadorModalOpen(false);
          }}
        >
          <AlertTitle>
            {formatMessage(messages.labelTitleErrorCambiarEstadoPrestador)}
          </AlertTitle>
          {formatMessage(messages.labelContentErrorCambiarEstadoPrestador)}
        </Alert>
      </Dialog>
    </>
  );
};

export default PrestadoresHabilitadosListView;
