import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'PresupuestosRankingPrestadores.title',
    description: 'Titulo para la tabla estadistica Ranking de Prestadores',
    defaultMessage: 'Ranking Prestadores',
  },
  colPromedio: {
    id: 'PresupuestosRankingPrestadores.colPromedio',
    description:
      'Header columa Promedio de la tabla estadistica Ranking de Prestadores',
    defaultMessage: 'Promedio',
  },
  colTotal: {
    id: 'PresupuestosRankingPrestadores.colTotal',
    description:
      'Header columa Total de la tabla estadistica Ranking de Prestadores',
    defaultMessage: 'Total ($)',
  },
  colCantidad: {
    id: 'PresupuestosRankingPrestadores.colCantidad',
    description:
      'Header columa Cantidad de la tabla estadistica Ranking de Prestadores',
    defaultMessage: 'Cantidad',
  },
  colPorcentaje: {
    id: 'PresupuestosRankingPrestadores.colPorcentaje',
    description:
      'Header columa Porcentaje de la tabla estadistica Ranking de Prestadores',
    defaultMessage: '%',
  },
});
