import React, { useEffect } from 'react';
import { useGetEstadisticaPresupuesto } from 'hooks/queries/useEstadisticaPresupuesto';
import PresupuestosEstadisticas from '../PresupuestosEstadisticas';

const PresupuestosEstadisticasContainer: React.FC<{
  desde: string;
  hasta: string;
  provinciasFilter: string;
  handleResultadoTabla: ({
    key,
    valor,
  }: {
    key: string;
    valor: boolean;
  }) => void;
}> = ({ desde, hasta, provinciasFilter, handleResultadoTabla }) => {
  const { data: estadisticaPresupuestos } = useGetEstadisticaPresupuesto({
    desde,
    hasta,
    provinciasFilter,
  });

  useEffect(() => {
    const tieneDatos = estadisticaPresupuestos?.length === 1;
    handleResultadoTabla({
      key: 'presupuestosEstadisticas',
      valor: tieneDatos,
    });
  }, [handleResultadoTabla, estadisticaPresupuestos?.length]);

  return estadisticaPresupuestos?.length === 1 ? (
    <PresupuestosEstadisticas estadistica={estadisticaPresupuestos[0]} />
  ) : null;
};

export default PresupuestosEstadisticasContainer;
