import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import {
  Box,
  Toolbar,
  IconButton,
  CssBaseline,
  AppBar,
  Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import DrawerNav from 'components/DrawerNav/DrawerNav';
import { getItem } from 'services/sessionStorageService';
import styles from './DefaultLayout.module.scss';

const KEY_ASEGURADORA_LOGGED =
  process.env.REACT_APP_KEY_ASEGURADORA_LOGGED ?? '';

const DefaultLayout: React.FC = () => {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [aseguradoraNombre, setAseguradoraNombre] = React.useState('');

  useEffect(() => {
    const valueKeyAseguradora = getItem(KEY_ASEGURADORA_LOGGED);
    if (valueKeyAseguradora) {
      setAseguradoraNombre(valueKeyAseguradora);
    }
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(open => !open);
  };

  return (
    <Box className={styles['layout-box-container']}>
      <CssBaseline />

      <AppBar position="fixed" className={styles['layout-bar-app']}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={styles['layout-icon-button']}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {aseguradoraNombre}
          </Typography>
        </Toolbar>
      </AppBar>

      <DrawerNav
        mobileOpen={mobileOpen}
        handleDrawerToggle={handleDrawerToggle}
      />

      <Box component="main" className={styles['layout-box-main']}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default DefaultLayout;
