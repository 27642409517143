// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Informe_dialog-container__9gbtS{width:25rem}.Informe_dialog-content-container__lHPxo{padding-left:1.5rem;padding-right:1.5rem;padding-bottom:1.5rem}.Informe_subir-button-container__TcZ2E>button{min-width:10rem}.Informe_buttons-container__tRf-G{margin-top:.5rem;display:flex;gap:.5rem}.Informe_download-link-label-container__Vvwdd{display:flex;flex-direction:column;align-items:center}.Informe_download-link-icon-container__YsoH\\+>svg{font-size:1.8rem}.Informe_download-link-text-container__E1HVx{font-size:.7rem;line-height:.4rem}", "",{"version":3,"sources":["webpack://./src/pages/Informe/Informe.module.scss"],"names":[],"mappings":"AAAA,iCACE,WAAA,CAGF,yCACE,mBAAA,CACA,oBAAA,CACA,qBAAA,CAIA,8CACE,eAAA,CAIJ,kCACE,gBAAA,CACA,YAAA,CACA,SAAA,CAGF,8CACE,YAAA,CACA,qBAAA,CACA,kBAAA,CAGF,kDACE,gBAAA,CAGF,6CACE,eAAA,CACA,iBAAA","sourcesContent":[".dialog-container {\n  width: 25rem;\n}\n\n.dialog-content-container {\n  padding-left: 1.5rem;\n  padding-right: 1.5rem;\n  padding-bottom: 1.5rem;\n}\n\n.subir-button-container {\n  > button {\n    min-width: 10rem;\n  }\n}\n\n.buttons-container {\n  margin-top: 0.5rem;\n  display: flex;\n  gap: 0.5rem;\n}\n\n.download-link-label-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.download-link-icon-container > svg {\n  font-size: 1.8rem;\n}\n\n.download-link-text-container {\n  font-size: 0.7rem;\n  line-height: 0.4rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog-container": "Informe_dialog-container__9gbtS",
	"dialog-content-container": "Informe_dialog-content-container__lHPxo",
	"subir-button-container": "Informe_subir-button-container__TcZ2E",
	"buttons-container": "Informe_buttons-container__tRf-G",
	"download-link-label-container": "Informe_download-link-label-container__Vvwdd",
	"download-link-icon-container": "Informe_download-link-icon-container__YsoH+",
	"download-link-text-container": "Informe_download-link-text-container__E1HVx"
};
export default ___CSS_LOADER_EXPORT___;
