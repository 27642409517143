import { useQuery } from '@tanstack/react-query';
import {
  informesService,
  GetCantidadInformesPorEstadoResponse,
} from 'services/informesService';

const CANTIDAD_INFORMES_POR_ESTADO_QUERY_KEY = 'cantidad-informes-por-estado';

const useCantidadInformesPorEstado = ({
  desde,
  hasta,
  provinciasFilter,
}: {
  desde: string;
  hasta: string;
  provinciasFilter: string;
}) => {
  return useQuery<GetCantidadInformesPorEstadoResponse, Error>(
    [
      CANTIDAD_INFORMES_POR_ESTADO_QUERY_KEY,
      { desde, hasta, provinciasFilter },
    ],
    () =>
      informesService.getCantidadInformesPorEstado({
        desde,
        hasta,
        provinciasFilter,
      }),
    {
      staleTime: 60 * 60 * 1000,
    },
  );
};

export { useCantidadInformesPorEstado, CANTIDAD_INFORMES_POR_ESTADO_QUERY_KEY };
