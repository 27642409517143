import { useQuery } from '@tanstack/react-query';
import { informesService } from 'services/informesService';

const HTML_INFORME_QUERY_KEY = 'htmlInforme';

const useGetHtmlInforme = ({
  idInforme,
  uuidInforme,
}: {
  idInforme: string;
  uuidInforme: string;
}) => {
  return useQuery<string, Error>(
    [HTML_INFORME_QUERY_KEY, idInforme, uuidInforme],
    () => informesService.getInformeHtml({ idInforme, uuidInforme }),
  );
};

export { useGetHtmlInforme, HTML_INFORME_QUERY_KEY };
