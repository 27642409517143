import React from 'react';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { FallbackProps } from 'react-error-boundary';
import { useIntl } from 'react-intl';
import messages from './messages';
import styles from './ErrorFallback.module.scss';

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const { formatMessage } = useIntl();
  const errorWithType = error as { message: string };
  const errorMessage = errorWithType.message;
  return (
    <Box mt={5}>
      <Card variant="outlined" className={styles['card-error']}>
        <CardContent>
          <Typography variant="h2" align="center">
            <WarningAmberIcon fontSize="large" color="error" />
          </Typography>
          <Typography variant="h6" align="center" gutterBottom color="error">
            {formatMessage(messages.title)}
          </Typography>
          <Typography variant="body1" align="center">
            {errorMessage}
          </Typography>
        </CardContent>
        <CardActions className={styles['card-error-actions']}>
          <Button
            variant="contained"
            size="large"
            color="error"
            onClick={resetErrorBoundary}
          >
            {formatMessage(messages.buttonText)}
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default ErrorFallback;
