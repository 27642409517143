import { request, RequestMethods } from 'clients/innboxes1';
import {
  Informe,
  InformesPaginadosResponse,
  ApiTrackInforme,
  UploadAdjuntoAInformeResponse,
  AgregarComentarioAInformeResponse,
  GetCantidadInformesPorEstadoResponse,
} from './types';

const URL_API_INFORMES = process.env.REACT_APP_API_URL_INFORMES || '';

const getInformes = async (): Promise<Informe[]> => {
  const data = <Informe[]>await request({
    method: RequestMethods.post,
    endpoint: 'api/informe/buscar',
  });

  return data;
};

const getInformesPaginado = async ({
  page = 1,
  perPage = 10,
  search = '',
}: {
  page: number;
  perPage: number;
  search: string;
}): Promise<InformesPaginadosResponse> => {
  if (search === 'error') {
    throw new Error('testing');
  }

  const endpointInformesPaginados = `?search=${search}&page=${page}&perPage=${perPage}`;
  const data = <InformesPaginadosResponse>await request({
    method: RequestMethods.get,
    baseUrl: URL_API_INFORMES,
    endpoint: endpointInformesPaginados,
  });

  return Promise.resolve(data);
};

const getInformeHtml = async ({
  idInforme,
  uuidInforme,
}: {
  idInforme: string;
  uuidInforme: string;
}) => {
  const informeHtmlEndpoint = `/api/informe/${idInforme}/${uuidInforme}/verPDF`;
  const data = <string>await request({
    method: RequestMethods.get,
    endpoint: informeHtmlEndpoint,
  });

  return data;
};

const uploadAdjuntoAInforme = async ({
  informeId,
  adjunto,
}: {
  informeId: string;
  adjunto: File;
}): Promise<UploadAdjuntoAInformeResponse> => {
  const formData = new FormData();
  formData.append('file', adjunto);

  const endpointUploadAdjuntoAInforme = `${informeId}/adjunto`;

  const uploadAdjuntoAInformeResponse = <UploadAdjuntoAInformeResponse>(
    await request({
      method: RequestMethods.post,
      baseUrl: URL_API_INFORMES,
      endpoint: endpointUploadAdjuntoAInforme,
      body: formData,
      defaultAccept: false,
      defaultContentType: false,
    })
  );

  return uploadAdjuntoAInformeResponse;
};

const agregarComentarioAInforme = async ({
  informeId,
  textoComentario,
}: {
  informeId: string;
  textoComentario: string;
}): Promise<AgregarComentarioAInformeResponse> => {
  const endpointAgregarComentarioAInforme = `${informeId}/comentario`;

  const agregarComentarioAInformeBody = {
    textoComentario,
  };

  const agregarComentarioAInformeResponse = <AgregarComentarioAInformeResponse>(
    await request({
      method: RequestMethods.post,
      baseUrl: URL_API_INFORMES,
      endpoint: endpointAgregarComentarioAInforme,
      body: JSON.stringify(agregarComentarioAInformeBody),
    })
  );

  return agregarComentarioAInformeResponse;
};

const getCantidadInformesPorEstado = async ({
  desde,
  hasta,
  provinciasFilter,
}: {
  desde: string;
  hasta: string;
  provinciasFilter: string;
}): Promise<GetCantidadInformesPorEstadoResponse> => {
  const paramsDesdeHasta = `desde=${desde}&hasta=${hasta}`;
  const queryParams =
    provinciasFilter !== ''
      ? `${paramsDesdeHasta}&${provinciasFilter}`
      : paramsDesdeHasta;

  const data = <GetCantidadInformesPorEstadoResponse>await request({
    method: RequestMethods.get,
    baseUrl: URL_API_INFORMES,
    endpoint: `getinformesporestado?${queryParams}`,
  });

  return data;
};

const trackInforme = async (patente: string): Promise<ApiTrackInforme> => {
  const data = <ApiTrackInforme>await request({
    method: RequestMethods.post,
    endpoint: 'api/v1/informes/trackInforme',
    body: JSON.stringify({ patente }),
    includeCredentials: false,
    includeToken: true,
  });

  if ('error' in data) {
    throw new Error(`${data.error[0]}`);
  }

  return data;
};

export default {
  getInformes,
  getInformesPaginado,
  trackInforme,
  getInformeHtml,
  uploadAdjuntoAInforme,
  agregarComentarioAInforme,
  getCantidadInformesPorEstado,
};
