import { defineMessages } from 'react-intl';

export default defineMessages({
  titlePage: {
    id: 'Dashboard.titlePage',
    description: 'Titulo página',
    defaultMessage: 'Dashboard',
  },
  labelFechaDesde: {
    id: 'Dashboard.labelFechaDesde',
    description: 'Label DatePicker Fecha Desde',
    defaultMessage: 'Fecha Desde',
  },
  labelFechaHasta: {
    id: 'Dashboard.labelFechaHasta',
    description: 'Label DatePicker Fecha Hasta',
    defaultMessage: 'Fecha Hasta',
  },
  buttonBuscar: {
    id: 'Dashboard.buttonBuscar',
    description: 'caption button Buscar',
    defaultMessage: 'Buscar',
  },
  textNoResults: {
    id: 'Dashboard.textNoResults',
    description: 'Texto cuando no se encontran resultados',
    defaultMessage:
      '¡No se encontraron datos para las estadísticas, en el periodo seleccionado!',
  },
});
