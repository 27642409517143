import { useMutation, useQueryClient } from '@tanstack/react-query';
import { HTML_INFORME_QUERY_KEY } from 'hooks/queries/useHtmlInforme';
import { informesService } from 'services/informesService';

const UPLOAD_ADJUNTO_A_INFORME_KEY = 'uploadAdjuntoAInforme';

const useUploadAdjuntoAInforme = ({ informeId }: { informeId: string }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ adjunto }: { adjunto: File }) =>
      informesService.uploadAdjuntoAInforme({
        informeId,
        adjunto,
      }),
    mutationKey: [UPLOAD_ADJUNTO_A_INFORME_KEY, informeId],
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: [HTML_INFORME_QUERY_KEY, informeId],
      });
    },
  });
};

export { useUploadAdjuntoAInforme, UPLOAD_ADJUNTO_A_INFORME_KEY };
