import { removeItem } from 'services/sessionStorageService';
import { request, RequestMethods } from 'clients/innboxes1';

const KEY_ASEGURADORA_LOGGED =
  process.env.REACT_APP_KEY_ASEGURADORA_LOGGED ?? '';

const login = async ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  const response = request({
    method: RequestMethods.post,
    endpoint: 'api/v1/portalAseguradora/login',
    body: JSON.stringify({ email, password }),
  });

  return response;
};

const logout = async () => {
  const response = request({
    method: RequestMethods.get,
    endpoint: 'api/v1/portal/logout',
  });

  if (KEY_ASEGURADORA_LOGGED) {
    removeItem(KEY_ASEGURADORA_LOGGED);
  }

  return response;
};

export default { login, logout };
