import React, { Fragment, useEffect, useState } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from '@mui/material';
import { useIntl } from 'react-intl';
import utils from 'utils';
import { TableRanking, TotalesRanking } from './types';
import messages from './messages';
import styles from './PresupuestosRankingPrestadores.module.scss';

const PresupuestosRankingPrestadores: React.FC<{
  ranking: TableRanking[];
  sortColumn: string;
  sortDirection: 'asc' | 'desc';
  handleSortingChange: ({ column }: { column: string }) => void;
}> = ({ ranking, sortColumn, sortDirection, handleSortingChange }) => {
  const { formatMessage } = useIntl();
  const [totalesRanking, setTotalesRanking] = useState<TotalesRanking>({
    total: 0,
    cantidad: 0,
  });

  useEffect(() => {
    const totales = ranking.reduce(
      (result: TotalesRanking, item) => {
        return {
          total: result.total + item.total,
          cantidad: result.cantidad + item.cantidad,
        };
      },
      { total: 0, cantidad: 0 },
    );
    setTotalesRanking(totales);
  }, [ranking]);

  return (
    <Box mt={2}>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead className={styles['th-color']}>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={sortColumn === 'Prestador'}
                  direction={
                    sortColumn === 'Prestador' ? sortDirection : 'desc'
                  }
                  onClick={() => handleSortingChange({ column: 'Prestador' })}
                >
                  <strong>{formatMessage(messages.title)}</strong>
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">
                <TableSortLabel
                  active={sortColumn === messages.colPromedio.defaultMessage}
                  direction={
                    sortColumn === messages.colPromedio.defaultMessage
                      ? sortDirection
                      : 'desc'
                  }
                  onClick={() =>
                    handleSortingChange({
                      column: messages.colPromedio.defaultMessage,
                    })
                  }
                >
                  {formatMessage(messages.colPromedio)}
                </TableSortLabel>
              </TableCell>
              <TableCell align="center">
                <TableSortLabel
                  active={sortColumn === messages.colTotal.defaultMessage}
                  direction={
                    sortColumn === messages.colTotal.defaultMessage
                      ? sortDirection
                      : 'desc'
                  }
                  onClick={() =>
                    handleSortingChange({
                      column: messages.colTotal.defaultMessage,
                    })
                  }
                >
                  {formatMessage(messages.colTotal)}
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={sortColumn === messages.colCantidad.defaultMessage}
                  direction={
                    sortColumn === messages.colCantidad.defaultMessage
                      ? sortDirection
                      : 'desc'
                  }
                  onClick={() =>
                    handleSortingChange({
                      column: messages.colCantidad.defaultMessage,
                    })
                  }
                >
                  {formatMessage(messages.colCantidad)}
                </TableSortLabel>
              </TableCell>
              <TableCell align="right">
                <TableSortLabel
                  active={sortColumn === messages.colPorcentaje.defaultMessage}
                  direction={
                    sortColumn === messages.colPorcentaje.defaultMessage
                      ? sortDirection
                      : 'desc'
                  }
                  onClick={() =>
                    handleSortingChange({
                      column: messages.colPorcentaje.defaultMessage,
                    })
                  }
                >
                  {formatMessage(messages.colPorcentaje)}
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {ranking?.length > 0 &&
              ranking.map(item => (
                <Fragment key={item.prestador}>
                  <TableRow key={item.prestador} className={styles['tr-total']}>
                    <TableCell className={styles['tc-col']}>
                      {item.prestador}
                    </TableCell>
                    <TableCell align="right" className={styles['tc-col']}>
                      {utils.convertirformatoMoneda({
                        value: Math.round(item.total / item.cantidad),
                      })}
                    </TableCell>
                    <TableCell align="right" className={styles['tc-col']}>
                      {utils.convertirformatoMoneda({ value: item.total })}
                    </TableCell>
                    <TableCell align="right" className={styles['tc-col']}>
                      {item.cantidad}
                    </TableCell>
                    <TableCell align="right" className={styles['tc-col']}>
                      {utils.calcularPorcentaje({
                        parcial: item.cantidad,
                        total: totalesRanking.cantidad,
                      })}
                      %
                    </TableCell>
                  </TableRow>
                  {item.adjudicados.length > 0 &&
                    item.adjudicados.map(adjudicado => (
                      <TableRow key={adjudicado.subtotal_adjudicados}>
                        <TableCell className={styles['tc-first-col']}>
                          <Typography variant="caption">
                            {adjudicado.razon}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          {utils.convertirformatoMoneda({
                            value: Math.round(
                              adjudicado.subtotal_adjudicados /
                                adjudicado.cantidad_adjudicados,
                            ),
                          })}
                        </TableCell>
                        <TableCell align="right">
                          {utils.convertirformatoMoneda({
                            value: adjudicado.subtotal_adjudicados,
                          })}
                        </TableCell>
                        <TableCell align="right">
                          {adjudicado.cantidad_adjudicados}
                        </TableCell>
                        <TableCell align="right">
                          {utils.calcularPorcentaje({
                            parcial: adjudicado.cantidad_adjudicados,
                            total: item.cantidad,
                          })}
                          %
                        </TableCell>
                      </TableRow>
                    ))}
                </Fragment>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PresupuestosRankingPrestadores;
