import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'PresupuestosEstadisticas.title',
    description: 'Titulo para la tabla estadistica de Presupuestos',
    defaultMessage: 'Presupuestos',
  },
  labelCantidad: {
    id: 'PresupuestosEstadisticas.labelCantidad',
    description: 'Etiueta Cantidad la tabla estadistica de Presupuestos',
    defaultMessage: 'Total',
  },
  subtitle: {
    id: 'PresupuestosEstadisticas.subtitle',
    description: 'Subtitulo la tabla estadistica de Presupuestos',
    defaultMessage: 'Tiempo Promedio de Respuesta (TPR):',
  },
  solicitados: {
    id: 'PresupuestosEstadisticas.solicitados',
    description: 'Etiqueta solicitados la tabla estadistica de Presupuestos',
    defaultMessage: 'Solicitados',
  },
  respondidos: {
    id: 'PresupuestosEstadisticas.respondidos',
    description:
      'Etiqueta respondidos para la tabla estadistica de Presupuestos',
    defaultMessage: 'Respondidos',
  },
  conDisponibilidad: {
    id: 'PresupuestosEstadisticas.conDisponibilidad',
    description:
      'Etiqueta conDisponibilidad la tabla estadistica de Presupuestos',
    defaultMessage: 'C./Disponibilidad',
  },
  sinDisponibilidad: {
    id: 'PresupuestosEstadisticas.sinDisponibilidad',
    description:
      'Etiqueta Sin Disponibilidad la tabla estadistica de Presupuestos',
    defaultMessage: 'S./Disponibilidad',
  },
});
