import { useQuery } from '@tanstack/react-query';
import {
  EstadisticaRankingPrestadores,
  presupuestosService,
} from 'services/presupuestosService';

const RANKING_PRESTADORES_QUERY_KEY = 'estadisticaRankingPrestadores';

const useEstadisticaRankingPrestadores = ({
  desde,
  hasta,
  provinciasFilter,
}: {
  desde: string;
  hasta: string;
  provinciasFilter: string;
}) => {
  return useQuery<EstadisticaRankingPrestadores[], Error>(
    [RANKING_PRESTADORES_QUERY_KEY, { desde, hasta, provinciasFilter }],
    () =>
      presupuestosService.getRankingPrestadores({
        desde,
        hasta,
        provinciasFilter,
      }),
    {
      staleTime: 60 * 60 * 1000,
    },
  );
};

export { useEstadisticaRankingPrestadores, RANKING_PRESTADORES_QUERY_KEY };
