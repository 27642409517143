import React from 'react';
import { Grid, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';

const ItemField: React.FC<{
  label: string;
  value: string;
  xs: number;
  sm: number;
  md: number;
}> = ({ label, value, xs, sm, md }) => (
  <Grid item xs={xs} sm={sm} md={md}>
    <Typography
      variant="caption"
      display="block"
      align="center"
      color={grey[500]}
    >
      {label}
    </Typography>
    <Typography variant="body2" gutterBottom align="center">
      {value}
    </Typography>
  </Grid>
);

export default ItemField;
