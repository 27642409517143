import { defineMessages } from 'react-intl';

export default defineMessages({
  headerColumnPromedio: {
    id: 'PresupuestosPromedioPrecios.headerColumnPromedio',
    description: 'Label para la columna Promedio',
    defaultMessage: 'Promedio',
  },
  labelAhorro: {
    id: 'PresupuestosPromedioPrecios.labelAhorro',
    description: 'Label para la fila Ahorro',
    defaultMessage: 'Ahorro',
  },
  headerColumnSuma: {
    id: 'PresupuestosPromedioPrecios.headerColumnSuma',
    description: 'Label para la columna Suma',
    defaultMessage: 'Suma',
  },
  preciosLicitados: {
    id: 'PresupuestosPromedioPrecios.preciosLicitados',
    description: 'Label para la fila de precios licitados',
    defaultMessage: 'Total Precios Licitados',
  },
  preciosContratados: {
    id: 'PresupuestosPromedioPrecios.preciosContratados',
    description: 'Label para la fila de precios contratados',
    defaultMessage: 'Total Precios Contratados',
  },
});
