import { defineMessages } from 'react-intl';

export default defineMessages({
  labelRazonSocial: {
    id: 'PrestadoresHabilitadosListView.labelRazonSocial',
    description: 'Razon Social del Prestador',
    defaultMessage: 'Prestador',
  },
  labelNombreFantasia: {
    id: 'PrestadoresHabilitadosListView.labelNombreFantasia',
    description: 'Nombre Fantasia del prestador',
    defaultMessage: 'Nombre Fantasia',
  },
  labelDireccion: {
    id: 'PrestadoresHabilitadosListView.labelDireccion',
    description: 'Direccion del prestador',
    defaultMessage: 'Dirección',
  },
  labelPresencia: {
    id: 'PrestadoresHabilitadosListView.labelPresencia',
    description: 'Localidades que tiene presencia el prestador',
    defaultMessage: 'Presencia',
  },
  labelProvincia: {
    id: 'PrestadoresHabilitadosListView.labelProvincia',
    description: 'Provincia del prestador',
    defaultMessage: 'Provincia',
  },
  labelCantidadSucursales: {
    id: 'PrestadoresHabilitadosListView.labelCantidadSucursales',
    description: 'Cantidad de sucursales del prestador',
    defaultMessage: 'Sucursales',
  },
  labelTipoPrestador: {
    id: 'PrestadoresHabilitadosListView.labelTipoPrestador',
    description: 'Tipo de prestador',
    defaultMessage: 'Rubro',
  },
  labelEstadoPrestador: {
    id: 'PrestadoresHabilitadosListView.labelEstadoPrestador',
    description: 'Estado del prestador',
    defaultMessage: 'Estado',
  },
  labelTituloComentario: {
    id: 'PrestadoresHabilitadosListView.labelTituloComentario',
    description: 'Titulo del comentario',
    defaultMessage: 'Agregar Comentario',
  },
  labelTextoComentario: {
    id: 'PrestadoresHabilitadosListView.labelTextoComentario',
    description: 'Texto del comentario',
    defaultMessage: 'Comentario',
  },
  labelPlaceHolderComentario: {
    id: 'PrestadoresHabilitadosListView.labelPlaceHolderComentario',
    description: 'Placeholder del comentario',
    defaultMessage: 'Escriba aquí un comentario',
  },
  labelEnviarComentario: {
    id: 'PrestadoresHabilitadosListView.labelEnviarComentario',
    description: 'Label boton envio comentario',
    defaultMessage: 'Enviar',
  },
  labelTitleErrorCambiarEstadoPrestador: {
    id: 'PrestadoresHabilitadosListView.labelTitleErrorCambiarEstadoPrestador',
    Description: 'Label titulo mensaje de error al cambiar estado de prestador',
    defaultMessage: 'Error',
  },
  labelContentErrorCambiarEstadoPrestador: {
    id: 'PrestadoresHabilitadosListView.labelContentErrorCambiarEstadoPrestador',
    Description: 'Label mensaje de error al cambiar estado de prestador',
    defaultMessage: 'No se pudo cambiar el estado del prestador',
  },
});
