import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  Button,
  Dialog,
  DialogTitle,
  Box,
  CircularProgress,
  TextField,
  Link,
} from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useGetHtmlInforme } from 'hooks/queries/useHtmlInforme';
import { useUploadAdjuntoAInforme } from 'hooks/mutations/useUploadAdjuntoAInforme';
import { useAgregarComentarioAInforme } from 'hooks/mutations/useAgregarComentarioAInforme';
import styles from './Informe.module.scss';
import messages from './messages';

const Informe: React.FC = (): JSX.Element => {
  const [selectFileModalOpen, setSelectFileModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const [agregarObservacionModalOpen, setAgregarObservacionModalOpen] =
    useState<boolean>(false);
  const [textoComentario, setTextoComentario] = useState<string>();
  const [fileUrl, setFileUrl] = useState<string>();
  const { idInforme, uuidInforme } = useParams();
  const { formatMessage } = useIntl();

  const BASE_URL = process.env.REACT_APP_BASE_URL ?? '';

  const uploadAdjuntoAInforme = useUploadAdjuntoAInforme({
    informeId: idInforme ?? '',
  });

  const agregarComentarioAInforme = useAgregarComentarioAInforme({
    informeId: idInforme ?? '',
  });
  const { data: htmlInforme } = useGetHtmlInforme({
    idInforme: idInforme ?? '',
    uuidInforme: uuidInforme ?? '',
  });

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleFileUpload = () => {
    if (!selectedFile) {
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      if (!idInforme) {
        throw new Error(
          'No se detectó un idInforme para relacionar al adjunto',
        );
      }

      uploadAdjuntoAInforme.mutate(
        { adjunto: selectedFile },
        {
          onSuccess: () => {
            setSelectFileModalOpen(false);
            setSelectedFile(undefined);
          },
        },
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error uploading file:', error);
    }
  };

  const handleAgregarComentario = () => {
    if (!textoComentario) {
      return;
    }

    try {
      if (!idInforme) {
        throw new Error(
          'No se detectó un idInforme para relacionar al adjunto',
        );
      }

      agregarComentarioAInforme.mutate(
        { textoComentario },
        {
          onSuccess: () => {
            setAgregarObservacionModalOpen(false);
            setTextoComentario(undefined);
          },
        },
      );
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error agregando comentario:', error);
    }
  };

  useEffect(() => {
    if (selectedFile) {
      setFileUrl(URL.createObjectURL(selectedFile));
    }
  }, [selectedFile]);

  return (
    <>
      <div>
        <Box className={styles['buttons-container']}>
          <Button
            variant="outlined"
            color="primary"
            component="span"
            onClick={() => setSelectFileModalOpen(true)}
          >
            {formatMessage(messages.subirAdjunto)}
          </Button>

          <Button
            variant="outlined"
            color="primary"
            component="span"
            onClick={() => setAgregarObservacionModalOpen(true)}
          >
            {formatMessage(messages.agregarObservacion)}
          </Button>

          {idInforme && (
            <Link
              href={`${BASE_URL}/api/informe/${idInforme}/getPDF`}
              underline="hover"
            >
              <div className={styles['download-link-label-container']}>
                <div className={styles['download-link-icon-container']}>
                  <CloudDownloadIcon />
                </div>
                <div className={styles['download-link-text-container']}>
                  {formatMessage(messages.descargar)}
                </div>
              </div>
            </Link>
          )}
        </Box>

        <Dialog
          PaperProps={{ className: styles['dialog-container'] }}
          open={selectFileModalOpen}
          onClose={() => setSelectFileModalOpen(false)}
        >
          <DialogTitle>{formatMessage(messages.subirAdjunto)}</DialogTitle>
          <Box className={styles['dialog-content-container']}>
            <Box textAlign="center">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label htmlFor="select-image">
                <Button variant="contained" color="primary" component="span">
                  {formatMessage(messages.seleccionarArchivo)}
                </Button>
              </label>
              <input
                id="select-image"
                type="file"
                style={{ display: 'none' }}
                onChange={handleFileChange}
              />
            </Box>

            {fileUrl && selectedFile && (
              <>
                <Box mt={2} textAlign="center">
                  <div> {formatMessage(messages.vistaPrevia)}</div>
                  <img src={fileUrl} alt={selectedFile.name} height="100px" />
                </Box>
                <Box
                  mt={2}
                  textAlign="center"
                  className={styles['subir-button-container']}
                >
                  <Button
                    onClick={() => {
                      handleFileUpload();
                    }}
                    disabled={!selectedFile}
                    variant="outlined"
                  >
                    {uploadAdjuntoAInforme?.isLoading ? (
                      <CircularProgress />
                    ) : (
                      'Subir'
                    )}
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Dialog>

        <Dialog
          PaperProps={{ className: styles['dialog-container'] }}
          open={agregarObservacionModalOpen}
          onClose={() => setAgregarObservacionModalOpen(false)}
        >
          <DialogTitle>
            {formatMessage(messages.agregarObservacion)}
          </DialogTitle>
          <Box className={styles['dialog-content-container']}>
            <Box textAlign="center">
              <TextField
                fullWidth
                id="nueva-observacion"
                label={formatMessage(messages.observacion)}
                multiline
                minRows={5}
                placeholder="Escriba aquí la observación."
                value={textoComentario}
                onChange={event => {
                  setTextoComentario(event.target?.value);
                }}
              />
            </Box>

            <Box
              mt={2}
              textAlign="center"
              className={styles['subir-button-container']}
            >
              <Button
                onClick={() => {
                  handleAgregarComentario();
                }}
                disabled={!textoComentario}
                variant="outlined"
              >
                {agregarComentarioAInforme?.isLoading ? (
                  <CircularProgress />
                ) : (
                  formatMessage(messages.enviar)
                )}
              </Button>
            </Box>
          </Box>
        </Dialog>
      </div>
      {/* eslint-disable-next-line react/no-danger */}
      <div dangerouslySetInnerHTML={{ __html: htmlInforme ?? '' }} />
    </>
  );
};

export default Informe;
