import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'ComprasPorMotivoAdjudicacion.title',
    description:
      'Titulo para la tabla estadistica Ranking de motico de adjudicación',
    defaultMessage: 'Compras',
  },
  headCantidad: {
    id: 'ComprasPorMotivoAdjudicacion.colCantidad',
    description:
      'Header columa Cantidad de la tabla estadistica Ranking de Compras',
    defaultMessage: 'Cantidad',
  },
  headPorcentaje: {
    id: 'ComprasPorMotivoAdjudicacion.colPorcentaje',
    description:
      'Header columa Porcentaje de la tabla estadistica Ranking de Compras',
    defaultMessage: '%',
  },
  colEnviadas: {
    id: 'ComprasPorMotivoAdjudicacion.colEnviadas',
    description: 'Titulo de la columna enviadas',
    defaultMessage: 'Enviadas',
  },
  colDocumentacion: {
    id: 'ComprasPorMotivoAdjudicacion.colDocumentadas',
    description: 'Titulo de la columna Documentacion cargada.',
    defaultMessage: 'Documentación cargada',
  },
});
