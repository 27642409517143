const setItem = (key: string, value: string) => {
  sessionStorage.setItem(key, value);
};

const getItem = (key: string): string | null => {
  return sessionStorage.getItem(key);
};

const removeItem = (key: string) => {
  sessionStorage.removeItem(key);
};

export { setItem, getItem, removeItem };
