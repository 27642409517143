import React from 'react';
import { MenuItem, Chip, Autocomplete, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { Provincia } from './types';
import styles from './ProvinciasFilterMultiSelect.module.scss';

const ProvinciasFiltereMultiselect: React.FC<{
  provincias: Provincia[];
  provinciasSelected: Provincia[];
  setProvinciasSelected: React.Dispatch<React.SetStateAction<Provincia[]>>;
}> = ({ provincias, provinciasSelected, setProvinciasSelected }) => {
  return (
    <Autocomplete
      multiple
      id="provincias-autocomplete"
      limitTags={1}
      value={provinciasSelected}
      onChange={(event, newValue) => {
        setProvinciasSelected([...newValue]);
      }}
      options={provincias}
      getOptionLabel={option => option.nombre}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option.nombre}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getTagProps({ index })}
          />
        ))
      }
      className={styles['autocomplete-style']}
      renderInput={params => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label="Selección Provincias"
          placeholder="Provincia"
        />
      )}
      renderOption={(props, option, { selected }) => (
        <MenuItem
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          key={option.nombre}
          value={option.nombre}
          sx={{ justifyContent: 'space-between' }}
        >
          {option.nombre}
          {selected ? <CheckIcon color="info" /> : null}
        </MenuItem>
      )}
    />
  );
};
export default ProvinciasFiltereMultiselect;
