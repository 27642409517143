import React, { useEffect, useState } from 'react';
import { Dayjs } from 'dayjs';
import useIntersectionObserver from 'hooks/queries/useIntersectionObserver';
import { Provincia } from 'components/ProvinciasFilterMultiselect';
import { EstadoInforme } from 'components/EstadosInformeFilterMultiSelect';
import { useGetInformesPaginadoInfiniteQuery } from '../../hooks/queries/useGetInformesPaginados';
import InformesListView from '../../components/InformesListView';
import provincias from '../../components/ProvinciasFilterMultiselect/provincias.json';
import {
  Informe,
  InformesPaginadosResponse,
} from '../../services/informesService';

const InformesListContainer: React.FC = () => {
  const LIMIT_PERPAGE_INFORMES = parseInt(
    process.env.REACT_APP_LIMIT_PERPAGE_INFORMES || '10',
    10,
  );
  const perPage = LIMIT_PERPAGE_INFORMES;
  const [isFiltered, setIsFiltered] = useState<boolean>(false);
  const [queryText, setQueryText] = useState<string>('');
  const [provinciasSelected, setProvinciasSelected] = useState<Provincia[]>([]);
  const [queryProvincias, setQueryProvincias] = useState<string>('');
  const [periodoSelected, setPeriodoSelected] = useState<Dayjs | null>(null);
  const [queryEstadosInforme, setQueryEstadosInforme] = useState<string>('');
  const [fechaDesde, setFechaDesde] = useState<string>('');
  const [fechaHasta, setFechaHasta] = useState<string>('');
  const [estadosInformeSelected, setEstadosInformeSelected] = useState<
    EstadoInforme[]
  >([]);
  const search = `{ "query": "${queryText}", "idsProvincias": [${queryProvincias}], "periodo": {"desde": "${fechaDesde}", "hasta": "${fechaHasta}"}, "estadosInforme": [${queryEstadosInforme}]}`;

  const { data, isSuccess, hasNextPage, fetchNextPage, isFetchingNextPage } =
    useGetInformesPaginadoInfiniteQuery({ perPage, search, isFiltered });

  const [ref, inView] = useIntersectionObserver();

  useEffect(() => {
    if (
      queryText !== '' ||
      provinciasSelected.length > 0 ||
      (fechaDesde && fechaHasta) ||
      estadosInformeSelected.length > 0
    ) {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }
  }, [
    queryText,
    provinciasSelected,
    fechaDesde,
    fechaHasta,
    estadosInformeSelected,
  ]);

  useEffect(() => {
    if (provinciasSelected.length !== 0) {
      setQueryProvincias(
        provinciasSelected.map(provincia => `"${provincia.id}"`).toString(),
      );
    } else {
      setQueryProvincias('');
    }
  }, [provinciasSelected]);

  useEffect(() => {
    if (estadosInformeSelected.length > 0) {
      setQueryEstadosInforme(
        estadosInformeSelected.map(estado => estado.id).toString(),
      );
    } else {
      setQueryEstadosInforme('');
    }
  }, [estadosInformeSelected]);

  useEffect(() => {
    if (queryText !== '') {
      setIsFiltered(true);
    } else {
      setIsFiltered(false);
    }
  }, [queryText]);

  useEffect(() => {
    if (periodoSelected) {
      const primerDiaDelMesSeleccionado = periodoSelected.startOf('month');
      setFechaDesde(primerDiaDelMesSeleccionado.format('YYYY-MM-DD'));
      setFechaHasta(
        primerDiaDelMesSeleccionado.add(1, 'M').format('YYYY-MM-DD'),
      );
    } else {
      setFechaDesde('');
      setFechaHasta('');
    }
  }, [periodoSelected]);

  useEffect(() => {
    if (inView && hasNextPage) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  const informes: Informe[] = isSuccess
    ? data.pages.map((page: InformesPaginadosResponse) => page.data).flat()
    : [];

  return (
    <InformesListView
      setQueryText={setQueryText}
      informes={informes}
      lastElement={ref}
      isFetchingNextPage={isFetchingNextPage}
      provincias={provincias}
      provinciasSelected={provinciasSelected}
      setProvinciasSelected={setProvinciasSelected}
      periodoSelected={periodoSelected}
      setPeriodoSelected={setPeriodoSelected}
      isFiltered={isFiltered}
      estadosInformeSelected={estadosInformeSelected}
      setEstadosInformeSelected={setEstadosInformeSelected}
    />
  );
};

export default InformesListContainer;
