import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { ResponsiveRadialBar } from '@nivo/radial-bar';
import { Box, Typography } from '@mui/material';
import { CantidadInformesPorEstado } from 'services/informesService';
import { useCantidadInformesPorEstado } from 'hooks/queries/useCantidadInformesPorEstado';
import styles from './PresupuestosGraficoCasosFinalizados.module.scss';
import messages from './messages';

const PresupuestosGraficoCasosFinalizados: React.FC<{
  desde: string;
  hasta: string;
  provinciasFilter: string;
  handleResultadoTabla: ({
    key,
    valor,
  }: {
    key: string;
    valor: boolean;
  }) => void;
}> = ({ desde, hasta, provinciasFilter, handleResultadoTabla }) => {
  const { formatMessage } = useIntl();
  const { data: cantidadInformesPorEstado } = useCantidadInformesPorEstado({
    desde,
    hasta,
    provinciasFilter,
  });

  useEffect(() => {
    if (cantidadInformesPorEstado) {
      const tieneDatos = cantidadInformesPorEstado.result.length > 0;
      handleResultadoTabla({
        key: 'graficoCasosFinalizados',
        valor: tieneDatos,
      });
    }
  }, [handleResultadoTabla, cantidadInformesPorEstado]);

  const estadoInicialCantidadInformesCerradosYAbiertos = {
    cerrados: 0,
    abiertos: 0,
  };

  const INFORME_ESTADO_TERMINADO_ID = 8;

  const cantidadInformesCerradosYAbiertos =
    cantidadInformesPorEstado?.result.reduce(
      (
        acc: { abiertos: number; cerrados: number },
        cur: CantidadInformesPorEstado,
      ) => {
        if (cur.status === INFORME_ESTADO_TERMINADO_ID) {
          return {
            abiertos: acc.abiertos,
            cerrados: +acc.cerrados + +cur.cantidad,
          };
        }
        return {
          abiertos: +acc.abiertos + +cur.cantidad,
          cerrados: acc.cerrados,
        };
      },
      estadoInicialCantidadInformesCerradosYAbiertos,
    ) ?? estadoInicialCantidadInformesCerradosYAbiertos;

  const cantidadInformePorEstadoChartData = [
    {
      id: 'Informes',
      data: [
        {
          x: 'Cerrados',
          y: cantidadInformesCerradosYAbiertos.cerrados,
        },
        {
          x: 'Abiertos',
          y: cantidadInformesCerradosYAbiertos.abiertos,
        },
      ],
    },
  ];

  return cantidadInformesPorEstado?.result?.length ? (
    <Box className={styles['grafico-casos-finalizados-container']}>
      <Box display="flex" alignContent="center" justifyContent="center">
        <Typography variant="h6">
          {formatMessage(messages.tituloGrafico)}
        </Typography>
      </Box>
      <ResponsiveRadialBar
        data={cantidadInformePorEstadoChartData}
        valueFormat=">-.0f"
        startAngle={-90}
        endAngle={90}
        padding={0.3}
        cornerRadius={5}
        margin={{ top: 60, right: 20, bottom: 20, left: 20 }}
        radialAxisStart={{ tickSize: 5, tickPadding: 5, tickRotation: 0 }}
        circularAxisOuter={{ tickSize: 5, tickPadding: 12, tickRotation: 0 }}
        labelsSkipAngle={0}
        labelsRadiusOffset={0.45}
        legends={[
          {
            anchor: 'bottom',
            direction: 'row',
            justify: false,
            translateX: 20,
            translateY: -50,
            itemsSpacing: 6,
            itemDirection: 'left-to-right',
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: '#999',
            symbolSize: 18,
            symbolShape: 'square',
            effects: [
              {
                on: 'hover',
                style: {
                  itemTextColor: '#000',
                },
              },
            ],
          },
        ]}
      />
    </Box>
  ) : null;
};

export default PresupuestosGraficoCasosFinalizados;
