import { useInfiniteQuery } from '@tanstack/react-query';
import { informesService } from 'services/informesService';
import type { InformesPaginadosResponse } from 'services/informesService';

const INFORMES_PAGINADO_QUERY_KEY = 'getInformesPaginado';

const useGetInformesPaginadoInfiniteQuery = ({
  perPage,
  search,
  isFiltered = false,
}: {
  perPage: number;
  search: string;
  isFiltered: boolean;
}) => {
  return useInfiniteQuery<InformesPaginadosResponse, Error>(
    [INFORMES_PAGINADO_QUERY_KEY, search],
    ({ pageParam = 1 }) =>
      informesService.getInformesPaginado({
        page: pageParam as number,
        perPage,
        search,
      }),
    {
      getNextPageParam: lastPage => {
        const nextPage =
          lastPage.currentPage < lastPage.totalPages
            ? lastPage.currentPage + 1
            : undefined;
        return nextPage;
      },
      enabled: isFiltered,
    },
  );
};

export { useGetInformesPaginadoInfiniteQuery, INFORMES_PAGINADO_QUERY_KEY };
