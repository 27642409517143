import React from 'react';
import { Alert, AlertTitle, Box, CardMedia } from '@mui/material';
import styles from './NoResults.module.scss';

const NoResults: React.FC<{
  title: string;
  message: string;
}> = ({ title, message }) => (
  <>
    <Box className={styles['alert-no-results']} mt={4}>
      <Alert variant="outlined" severity="info">
        <AlertTitle>
          <strong>{title}</strong>{' '}
        </AlertTitle>
        {message}
      </Alert>
    </Box>
    <Box
      flexGrow={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      className={styles['box-logo']}
    >
      <CardMedia component="img" image="./appsolut_logo_chico.jpg" />
    </Box>
  </>
);

export default NoResults;
