import React, { useEffect } from 'react';
import { useGetEstadisticaPromedioPrecios } from 'hooks/queries/useEstadisticaPromedioPrecios';
import PresupuestosPromedioPrecios from '../PresupuestosPromedioPrecios';

const PresupuestosPromedioPreciosContainer: React.FC<{
  desde: string;
  hasta: string;
  provinciasFilter: string;
  handleResultadoTabla: ({
    key,
    valor,
  }: {
    key: string;
    valor: boolean;
  }) => void;
}> = ({ desde, hasta, provinciasFilter, handleResultadoTabla }) => {
  const { data: promedioPrecios } = useGetEstadisticaPromedioPrecios({
    desde,
    hasta,
    provinciasFilter,
  });

  useEffect(() => {
    const tieneDatos = promedioPrecios?.length === 1;
    handleResultadoTabla({
      key: 'estadisticasPromedioPrecios',
      valor: tieneDatos,
    });
  }, [handleResultadoTabla, promedioPrecios?.length]);

  return promedioPrecios?.length === 1 ? (
    <PresupuestosPromedioPrecios promedioPrecios={promedioPrecios[0]} />
  ) : null;
};

export default PresupuestosPromedioPreciosContainer;
