import React from 'react';
import { MenuItem, Chip, Autocomplete, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useIntl } from 'react-intl';
import { EstadoInforme } from './types';
import messages from './messages';
import styles from './EstadosInformeFilterMultiSelect.module.scss';

const EstadosInformeFilterMultiSelect: React.FC<{
  estadosInforme: EstadoInforme[];
  estadosInformeSelected: EstadoInforme[];
  setEstadosInformeSelected: React.Dispatch<
    React.SetStateAction<EstadoInforme[]>
  >;
}> = ({
  estadosInforme,
  estadosInformeSelected,
  setEstadosInformeSelected,
}) => {
  const { formatMessage } = useIntl();
  return (
    <Autocomplete
      multiple
      id="etapas-autocomplete"
      limitTags={1}
      value={estadosInformeSelected}
      onChange={(event, newValue) => {
        setEstadosInformeSelected([...newValue]);
      }}
      options={estadosInforme}
      getOptionLabel={option => option.nombre}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            label={option.nombre}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getTagProps({ index })}
          />
        ))
      }
      className={styles['autocomplete-style']}
      renderInput={params => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label={formatMessage(messages.labelEstadoInformes)}
          placeholder={formatMessage(messages.estadosInformePlaceholder)}
        />
      )}
      renderOption={(props, option, { selected }) => (
        <MenuItem
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
          key={`${option.id}${option.nombre}`}
          value={option.nombre}
          sx={{ justifyContent: 'space-between' }}
        >
          {option.nombre}
          {selected ? <CheckIcon color="info" /> : null}
        </MenuItem>
      )}
    />
  );
};
export default EstadosInformeFilterMultiSelect;
