import React, { Fragment, useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { utils, writeFileXLSX } from 'xlsx';
import { Informe } from 'services/informesService';
import {
  Box,
  Button,
  Divider,
  Grid,
  InputBase,
  Paper,
  Typography,
} from '@mui/material';
import { StyledEngineProvider } from '@mui/material/styles';
import { CloudDownload, NewspaperOutlined } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import ItemField from 'components/ItemField';
import Loading from 'components/Loading';
import NoResults from 'components/NoResults';
import ProvinciasFiltereMultiselect, {
  Provincia,
} from 'components/ProvinciasFilterMultiselect';
import PeriodoFilterMesAnio from 'components/PeriodoFilterMesAnio';
import dayjs, { Dayjs } from 'dayjs';
import estadosInforme from 'pages/InformesListContainer/estadosInforme.json';
import EstadosInformeFilterMultiSelect, {
  EstadoInforme,
} from 'components/EstadosInformeFilterMultiSelect';
import messages from './messages';
import styles from './InformesListView.module.scss';

const CATEGORIA_TERCEROS = 'terceros';

const InformesListView: React.FC<{
  setQueryText: React.Dispatch<React.SetStateAction<string>>;
  informes: Informe[];
  lastElement: React.RefObject<HTMLInputElement>;
  isFetchingNextPage: boolean;
  provincias: Provincia[];
  provinciasSelected: Provincia[];
  setProvinciasSelected: React.Dispatch<React.SetStateAction<Provincia[]>>;
  periodoSelected: Dayjs | null;
  setPeriodoSelected: React.Dispatch<React.SetStateAction<Dayjs | null>>;
  isFiltered: boolean;
  estadosInformeSelected: EstadoInforme[];
  setEstadosInformeSelected: React.Dispatch<
    React.SetStateAction<EstadoInforme[]>
  >;
}> = ({
  setQueryText,
  informes,
  lastElement,
  isFetchingNextPage,
  provincias,
  provinciasSelected,
  setProvinciasSelected,
  periodoSelected,
  setPeriodoSelected,
  isFiltered,
  estadosInformeSelected,
  setEstadosInformeSelected,
}) => {
  const { formatMessage } = useIntl();
  const exportFile = useCallback(() => {
    const listaAExportar = informes.map(
      ({
        id,
        nid,
        siniestro,
        aseguradora_nombre,
        usuario_asignado,
        contacto,
        patente,
        vehiculo_marca,
        vehiculo_modelo,
        status_texto,
        provincia_nombre,
        localidad_nombre,
        created_at,
      }) => ({
        id,
        Codigo: nid,
        Siniestro: siniestro,
        Aseguradora: aseguradora_nombre,
        Asignado: usuario_asignado,
        Asegurado: contacto,
        Patente: patente,
        'V Modelo': `${vehiculo_marca} ${vehiculo_modelo}`,
        Etapa: status_texto,
        Provincia_Localidad: `${provincia_nombre}/${localidad_nombre}`,
        Ingreso: created_at,
      }),
    );
    const ws = utils.json_to_sheet(listaAExportar);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');
    writeFileXLSX(wb, 'Informes.xlsx');
  }, [informes]);
  const [searchText, setSearchText] = useState<string>('');
  const handlerClickSearch = () => {
    setQueryText(searchText);
  };

  return (
    <>
      <StyledEngineProvider injectFirst>
        <Grid
          container
          justifyContent="space-around"
          alignItems="center"
          rowSpacing={1}
          columnSpacing={1}
          mt={2}
        >
          <Grid item xs={6} sm={6} md={8}>
            <Typography variant="h6" align="left">
              <strong>{formatMessage(messages.titleList)}</strong>
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={6}
            md={4}
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
          >
            <Button
              variant="outlined"
              size="small"
              startIcon={<CloudDownload />}
              onClick={exportFile}
            >
              Excel
            </Button>
          </Grid>
          <Grid item xs={12} sm={7} md={5} lg={3}>
            <ProvinciasFiltereMultiselect
              provincias={provincias}
              provinciasSelected={provinciasSelected}
              setProvinciasSelected={setProvinciasSelected}
            />
          </Grid>
          <Grid item xs={4} sm={5} md={3} lg={2}>
            <PeriodoFilterMesAnio
              periodoSelected={periodoSelected}
              setPeriodoSelected={setPeriodoSelected}
            />
          </Grid>
          <Grid item xs={8} sm={7} md={4} lg={3}>
            <EstadosInformeFilterMultiSelect
              estadosInforme={estadosInforme}
              estadosInformeSelected={estadosInformeSelected}
              setEstadosInformeSelected={setEstadosInformeSelected}
            />
          </Grid>
          <Grid item xs={12} sm={5} md={12} lg={4}>
            <Paper
              component="form"
              elevation={3}
              className={styles['paper-search']}
            >
              <InputBase
                placeholder={formatMessage(messages.inputBasePlaceholder)}
                inputProps={{
                  'aria-label': formatMessage(messages.inputBaseLabel),
                }}
                value={searchText}
                onInput={e => {
                  const inputValue = (e.target as HTMLInputElement).value;
                  setSearchText(inputValue);
                }}
                className={styles['input-base-search']}
              />
              <Divider
                orientation="vertical"
                className={styles['input-divider-search']}
              />
              <Button variant="contained" onClick={handlerClickSearch}>
                {formatMessage(messages.buttonBuscarTexto)}
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </StyledEngineProvider>

      <Divider className={styles['divider-title']} />
      {isFiltered ? (
        <Grid
          container
          justifyContent="space-around"
          alignItems="center"
          rowSpacing={1}
          marginTop={0.5}
        >
          {informes.length > 0 ? (
            informes.map(informe => (
              <Fragment key={informe.id}>
                <Grid item xs={10} md={10} lg={10} container>
                  <ItemField
                    label={formatMessage(messages.labelId)}
                    value={informe.informeid}
                    xs={4}
                    sm={3}
                    md={1}
                  />
                  <ItemField
                    label={formatMessage(messages.labelCodigo)}
                    value={informe.nid}
                    xs={4}
                    sm={3}
                    md={1}
                  />
                  <ItemField
                    label={formatMessage(messages.labelSiniestro)}
                    value={informe.siniestro}
                    xs={4}
                    sm={3}
                    md={2}
                  />
                  <ItemField
                    label={formatMessage(messages.labelAsignado)}
                    value={informe.usuario_asignado || ''}
                    xs={6}
                    sm={3}
                    md={2}
                  />
                  <ItemField
                    label={formatMessage(messages.labelAsegurado)}
                    value={informe.contacto}
                    xs={6}
                    sm={4}
                    md={4}
                  />
                  <ItemField
                    label={formatMessage(messages.labelPatente)}
                    value={informe.patente}
                    xs={6}
                    sm={3}
                    md={2}
                  />
                  <ItemField
                    label={formatMessage(messages.labelVehiculo)}
                    value={`${informe.vehiculo_marca} - ${informe.vehiculo_modelo}`}
                    xs={6}
                    sm={5}
                    md={4}
                  />
                  <ItemField
                    label={formatMessage(messages.labelProvincia)}
                    value={informe.provincia_nombre}
                    xs={6}
                    sm={3}
                    md={2}
                  />
                  <ItemField
                    label={formatMessage(messages.labelLocalidad)}
                    value={informe.localidad_nombre}
                    xs={6}
                    sm={3}
                    md={2}
                  />
                  <ItemField
                    label={formatMessage(messages.labelStatus)}
                    value={informe.status_texto}
                    xs={6}
                    sm={3}
                    md={2}
                  />
                  <ItemField
                    label={formatMessage(messages.labelFechaIngreso)}
                    value={dayjs(informe.created_at).format('DD-MM-YYYY HH:MM')}
                    xs={6}
                    sm={3}
                    md={2}
                  />
                </Grid>
                <Grid item>
                  <Box>
                    <Link to={`/informes/${informe.informeid}/${informe.id}`}>
                      <Button
                        // href={getUrlReport(informe.id, informe.uuid)}
                        // target="_blank"
                        variant="contained"
                        startIcon={<NewspaperOutlined />}
                      >
                        {informe.categoria === CATEGORIA_TERCEROS
                          ? formatMessage(messages.buttonReportTerceros)
                          : formatMessage(messages.buttonReport)}
                      </Button>
                    </Link>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Divider className={styles['divider-row']} />
                </Grid>
              </Fragment>
            ))
          ) : (
            <NoResults
              title={formatMessage(messages.titleList)}
              message={formatMessage(messages.labelNoResults)}
            />
          )}
        </Grid>
      ) : (
        <NoResults
          title={formatMessage(messages.titleList)}
          message={formatMessage(messages.labelNoFiltered)}
        />
      )}
      <div className="app">
        <div ref={lastElement} />
        {isFetchingNextPage && <Loading />}
      </div>
    </>
  );
};

export default InformesListView;
